(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === elm$core$Basics$EQ ? 0 : ord === elm$core$Basics$LT ? -1 : 1;
	}));
});



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = elm$core$Set$toList(x);
		y = elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = elm$core$Dict$toList(x);
		y = elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = elm$core$Dict$toList(x);
		y = elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? elm$core$Basics$LT : n ? elm$core$Basics$GT : elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.bJ.aW === region.b3.aW)
	{
		return 'on line ' + region.bJ.aW;
	}
	return 'on lines ' + region.bJ.aW + ' through ' + region.b3.aW;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? elm$core$Maybe$Nothing
		: elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? elm$core$Maybe$Just(n) : elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




/**_UNUSED/
function _Json_errorToString(error)
{
	return elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? elm$core$Result$Ok(value)
		: (value instanceof String)
			? elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return (elm$core$Result$isOk(result)) ? result : elm$core$Result$Err(A2(elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return (elm$core$Result$isOk(result)) ? result : elm$core$Result$Err(A2(elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!elm$core$Result$isOk(result))
					{
						return elm$core$Result$Err(A2(elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return elm$core$Result$Ok(elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if (elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return elm$core$Result$Err(elm$json$Json$Decode$OneOf(elm$core$List$reverse(errors)));

		case 1:
			return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!elm$core$Result$isOk(result))
		{
			return elm$core$Result$Err(A2(elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList === 'function' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2(elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cZ,
		impl.dg,
		impl.dc,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2(elm$json$Json$Decode$map, func, handler.a)
				:
			A3(elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		T: func(record.T),
		bL: record.bL,
		bI: record.bI
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.T;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.bL;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.bI) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cZ,
		impl.dg,
		impl.dc,
		function(sendToApp, initialModel) {
			var view = impl.dj;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cZ,
		impl.dg,
		impl.dc,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.a$ && impl.a$(sendToApp)
			var view = impl.dj;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.cL);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.de) && (_VirtualDom_doc.title = title = doc.de);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.c3;
	var onUrlRequest = impl.c4;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		a$: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.co === next.co
							&& curr.b8 === next.b8
							&& curr.ck.a === next.ck.a
						)
							? elm$browser$Browser$Internal(next)
							: elm$browser$Browser$External(href)
					));
				}
			});
		},
		cZ: function(flags)
		{
			return A3(impl.cZ, flags, _Browser_getUrl(), key);
		},
		dj: impl.dj,
		dg: impl.dg,
		dc: impl.dc
	});
}

function _Browser_getUrl()
{
	return elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return elm$core$Result$isOk(result) ? elm$core$Maybe$Just(result.a) : elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { cW: 'hidden', cM: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { cW: 'mozHidden', cM: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { cW: 'msHidden', cM: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { cW: 'webkitHidden', cM: 'webkitvisibilitychange' }
		: { cW: 'hidden', cM: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail(elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		cu: _Browser_getScene(),
		bO: {
			br: _Browser_window.pageXOffset,
			dl: _Browser_window.pageYOffset,
			bq: _Browser_doc.documentElement.clientWidth,
			aT: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		bq: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		aT: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			cu: {
				bq: node.scrollWidth,
				aT: node.scrollHeight
			},
			bO: {
				br: node.scrollLeft,
				dl: node.scrollTop,
				bq: node.clientWidth,
				aT: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			cu: _Browser_getScene(),
			bO: {
				br: x,
				dl: y,
				bq: _Browser_doc.documentElement.clientWidth,
				aT: _Browser_doc.documentElement.clientHeight
			},
			cR: {
				br: x + rect.left,
				dl: y + rect.top,
				bq: rect.width,
				aT: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2(elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});
var author$project$Main$LinkClicked = function (a) {
	return {$: 9, a: a};
};
var author$project$Main$UrlChanged = function (a) {
	return {$: 10, a: a};
};
var author$project$Main$WindowResize = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var elm$core$Basics$EQ = 1;
var elm$core$Basics$GT = 2;
var elm$core$Basics$LT = 0;
var elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3(elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var elm$core$List$cons = _List_cons;
var elm$core$Dict$toList = function (dict) {
	return A3(
		elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var elm$core$Dict$keys = function (dict) {
	return A3(
		elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2(elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var elm$core$Set$toList = function (_n0) {
	var dict = _n0;
	return elm$core$Dict$keys(dict);
};
var elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var elm$core$Array$foldr = F3(
	function (func, baseCase, _n0) {
		var tree = _n0.c;
		var tail = _n0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3(elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3(elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			elm$core$Elm$JsArray$foldr,
			helper,
			A3(elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var elm$core$Array$toList = function (array) {
	return A3(elm$core$Array$foldr, elm$core$List$cons, _List_Nil, array);
};
var elm$core$Basics$floor = _Basics_floor;
var author$project$Main$windwoResizeFromVp = function (vp) {
	return A2(
		author$project$Main$WindowResize,
		elm$core$Basics$floor(vp.bO.bq),
		elm$core$Basics$floor(vp.bO.aT));
};
var elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var elm$browser$Browser$Dom$NotFound = elm$core$Basics$identity;
var elm$core$Basics$never = function (_n0) {
	never:
	while (true) {
		var nvr = _n0;
		var $temp$_n0 = nvr;
		_n0 = $temp$_n0;
		continue never;
	}
};
var elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var elm$core$Maybe$Nothing = {$: 1};
var elm$core$Basics$False = 1;
var elm$core$Basics$True = 0;
var elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var elm$core$Basics$identity = function (x) {
	return x;
};
var elm$core$Task$Perform = elm$core$Basics$identity;
var elm$core$Task$succeed = _Scheduler_succeed;
var elm$core$Task$init = elm$core$Task$succeed(0);
var elm$core$Basics$add = _Basics_add;
var elm$core$Basics$gt = _Utils_gt;
var elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var elm$core$List$reverse = function (list) {
	return A3(elm$core$List$foldl, elm$core$List$cons, _List_Nil, list);
};
var elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							elm$core$List$foldl,
							fn,
							acc,
							elm$core$List$reverse(r4)) : A4(elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4(elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var elm$core$Task$andThen = _Scheduler_andThen;
var elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			elm$core$Task$andThen,
			function (a) {
				return elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			elm$core$Task$andThen,
			function (a) {
				return A2(
					elm$core$Task$andThen,
					function (b) {
						return elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var elm$core$Task$sequence = function (tasks) {
	return A3(
		elm$core$List$foldr,
		elm$core$Task$map2(elm$core$List$cons),
		elm$core$Task$succeed(_List_Nil),
		tasks);
};
var elm$core$Array$branchFactor = 32;
var elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var elm$core$Basics$ceiling = _Basics_ceiling;
var elm$core$Basics$fdiv = _Basics_fdiv;
var elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var elm$core$Basics$toFloat = _Basics_toFloat;
var elm$core$Array$shiftStep = elm$core$Basics$ceiling(
	A2(elm$core$Basics$logBase, 2, elm$core$Array$branchFactor));
var elm$core$Elm$JsArray$empty = _JsArray_empty;
var elm$core$Array$empty = A4(elm$core$Array$Array_elm_builtin, 0, elm$core$Array$shiftStep, elm$core$Elm$JsArray$empty, elm$core$Elm$JsArray$empty);
var elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _n0 = A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, nodes);
			var node = _n0.a;
			var remainingNodes = _n0.b;
			var newAcc = A2(
				elm$core$List$cons,
				elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var elm$core$Basics$eq = _Utils_equal;
var elm$core$Tuple$first = function (_n0) {
	var x = _n0.a;
	return x;
};
var elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = elm$core$Basics$ceiling(nodeListSize / elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2(elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var elm$core$Basics$mul = _Basics_mul;
var elm$core$Basics$sub = _Basics_sub;
var elm$core$Elm$JsArray$length = _JsArray_length;
var elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.h) {
			return A4(
				elm$core$Array$Array_elm_builtin,
				elm$core$Elm$JsArray$length(builder.j),
				elm$core$Array$shiftStep,
				elm$core$Elm$JsArray$empty,
				builder.j);
		} else {
			var treeLen = builder.h * elm$core$Array$branchFactor;
			var depth = elm$core$Basics$floor(
				A2(elm$core$Basics$logBase, elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? elm$core$List$reverse(builder.m) : builder.m;
			var tree = A2(elm$core$Array$treeFromBuilder, correctNodeList, builder.h);
			return A4(
				elm$core$Array$Array_elm_builtin,
				elm$core$Elm$JsArray$length(builder.j) + treeLen,
				A2(elm$core$Basics$max, 5, depth * elm$core$Array$shiftStep),
				tree,
				builder.j);
		}
	});
var elm$core$Basics$idiv = _Basics_idiv;
var elm$core$Basics$lt = _Utils_lt;
var elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					elm$core$Array$builderToArray,
					false,
					{m: nodeList, h: (len / elm$core$Array$branchFactor) | 0, j: tail});
			} else {
				var leaf = elm$core$Array$Leaf(
					A3(elm$core$Elm$JsArray$initialize, elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2(elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var elm$core$Basics$le = _Utils_le;
var elm$core$Basics$remainderBy = _Basics_remainderBy;
var elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return elm$core$Array$empty;
		} else {
			var tailLen = len % elm$core$Array$branchFactor;
			var tail = A3(elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - elm$core$Array$branchFactor;
			return A5(elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var elm$core$Basics$and = _Basics_and;
var elm$core$Basics$append = _Utils_append;
var elm$core$Basics$or = _Basics_or;
var elm$core$Char$toCode = _Char_toCode;
var elm$core$Char$isLower = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var elm$core$Char$isUpper = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var elm$core$Char$isAlpha = function (_char) {
	return elm$core$Char$isLower(_char) || elm$core$Char$isUpper(_char);
};
var elm$core$Char$isDigit = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var elm$core$Char$isAlphaNum = function (_char) {
	return elm$core$Char$isLower(_char) || (elm$core$Char$isUpper(_char) || elm$core$Char$isDigit(_char));
};
var elm$core$List$length = function (xs) {
	return A3(
		elm$core$List$foldl,
		F2(
			function (_n0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var elm$core$List$map2 = _List_map2;
var elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2(elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var elm$core$List$range = F2(
	function (lo, hi) {
		return A3(elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			elm$core$List$map2,
			f,
			A2(
				elm$core$List$range,
				0,
				elm$core$List$length(xs) - 1),
			xs);
	});
var elm$core$String$all = _String_all;
var elm$core$String$fromInt = _String_fromNumber;
var elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var elm$core$String$uncons = _String_uncons;
var elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var elm$json$Json$Decode$indent = function (str) {
	return A2(
		elm$core$String$join,
		'\n    ',
		A2(elm$core$String$split, '\n', str));
};
var elm$json$Json$Encode$encode = _Json_encode;
var elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + (elm$core$String$fromInt(i + 1) + (') ' + elm$json$Json$Decode$indent(
			elm$json$Json$Decode$errorToString(error))));
	});
var elm$json$Json$Decode$errorToString = function (error) {
	return A2(elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _n1 = elm$core$String$uncons(f);
						if (_n1.$ === 1) {
							return false;
						} else {
							var _n2 = _n1.a;
							var _char = _n2.a;
							var rest = _n2.b;
							return elm$core$Char$isAlpha(_char) && A2(elm$core$String$all, elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2(elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + (elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2(elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									elm$core$String$join,
									'',
									elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										elm$core$String$join,
										'',
										elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + (elm$core$String$fromInt(
								elm$core$List$length(errors)) + ' ways:'));
							return A2(
								elm$core$String$join,
								'\n\n',
								A2(
									elm$core$List$cons,
									introduction,
									A2(elm$core$List$indexedMap, elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								elm$core$String$join,
								'',
								elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + (elm$json$Json$Decode$indent(
						A2(elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var elm$core$Platform$sendToApp = _Platform_sendToApp;
var elm$core$Task$spawnCmd = F2(
	function (router, _n0) {
		var task = _n0;
		return _Scheduler_spawn(
			A2(
				elm$core$Task$andThen,
				elm$core$Platform$sendToApp(router),
				task));
	});
var elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			elm$core$Task$map,
			function (_n0) {
				return 0;
			},
			elm$core$Task$sequence(
				A2(
					elm$core$List$map,
					elm$core$Task$spawnCmd(router),
					commands)));
	});
var elm$core$Task$onSelfMsg = F3(
	function (_n0, _n1, _n2) {
		return elm$core$Task$succeed(0);
	});
var elm$core$Task$cmdMap = F2(
	function (tagger, _n0) {
		var task = _n0;
		return A2(elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager(elm$core$Task$init, elm$core$Task$onEffects, elm$core$Task$onSelfMsg, elm$core$Task$cmdMap);
var elm$core$Task$command = _Platform_leaf('Task');
var elm$core$Task$perform = F2(
	function (toMessage, task) {
		return elm$core$Task$command(
			A2(elm$core$Task$map, toMessage, task));
	});
var elm$json$Json$Decode$map = _Json_map1;
var elm$json$Json$Decode$map2 = _Json_map2;
var elm$json$Json$Decode$succeed = _Json_succeed;
var elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var elm$core$String$length = _String_length;
var elm$core$String$slice = _String_slice;
var elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			elm$core$String$slice,
			n,
			elm$core$String$length(string),
			string);
	});
var elm$core$String$startsWith = _String_startsWith;
var elm$url$Url$Http = 0;
var elm$url$Url$Https = 1;
var elm$core$String$indexes = _String_indexes;
var elm$core$String$isEmpty = function (string) {
	return string === '';
};
var elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(elm$core$String$slice, 0, n, string);
	});
var elm$core$String$contains = _String_contains;
var elm$core$String$toInt = _String_toInt;
var elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {b6: fragment, b8: host, c6: path, ck: port_, co: protocol, cp: query};
	});
var elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if (elm$core$String$isEmpty(str) || A2(elm$core$String$contains, '@', str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, ':', str);
			if (!_n0.b) {
				return elm$core$Maybe$Just(
					A6(elm$url$Url$Url, protocol, str, elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_n0.b.b) {
					var i = _n0.a;
					var _n1 = elm$core$String$toInt(
						A2(elm$core$String$dropLeft, i + 1, str));
					if (_n1.$ === 1) {
						return elm$core$Maybe$Nothing;
					} else {
						var port_ = _n1;
						return elm$core$Maybe$Just(
							A6(
								elm$url$Url$Url,
								protocol,
								A2(elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return elm$core$Maybe$Nothing;
				}
			}
		}
	});
var elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '/', str);
			if (!_n0.b) {
				return A5(elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _n0.a;
				return A5(
					elm$url$Url$chompBeforePath,
					protocol,
					A2(elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '?', str);
			if (!_n0.b) {
				return A4(elm$url$Url$chompBeforeQuery, protocol, elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _n0.a;
				return A4(
					elm$url$Url$chompBeforeQuery,
					protocol,
					elm$core$Maybe$Just(
						A2(elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '#', str);
			if (!_n0.b) {
				return A3(elm$url$Url$chompBeforeFragment, protocol, elm$core$Maybe$Nothing, str);
			} else {
				var i = _n0.a;
				return A3(
					elm$url$Url$chompBeforeFragment,
					protocol,
					elm$core$Maybe$Just(
						A2(elm$core$String$dropLeft, i + 1, str)),
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$fromString = function (str) {
	return A2(elm$core$String$startsWith, 'http://', str) ? A2(
		elm$url$Url$chompAfterProtocol,
		0,
		A2(elm$core$String$dropLeft, 7, str)) : (A2(elm$core$String$startsWith, 'https://', str) ? A2(
		elm$url$Url$chompAfterProtocol,
		1,
		A2(elm$core$String$dropLeft, 8, str)) : elm$core$Maybe$Nothing);
};
var elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var rtfeldman$elm_css$Css$Structure$Compatible = 0;
var rtfeldman$elm_css$Css$crosshair = {a: 0, M: 'crosshair'};
var rtfeldman$elm_css$Css$Preprocess$AppendProperty = function (a) {
	return {$: 0, a: a};
};
var rtfeldman$elm_css$Css$property = F2(
	function (key, value) {
		return rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var rtfeldman$elm_css$Css$prop1 = F2(
	function (key, arg) {
		return A2(rtfeldman$elm_css$Css$property, key, arg.M);
	});
var rtfeldman$elm_css$Css$cursor = rtfeldman$elm_css$Css$prop1('cursor');
var rtfeldman$elm_css$Css$hidden = {u: 0, aC: 0, M: 'hidden', a6: 0};
var rtfeldman$elm_css$Css$overflow = rtfeldman$elm_css$Css$prop1('overflow');
var rtfeldman$elm_css$Css$overflowY = rtfeldman$elm_css$Css$prop1('overflow-y');
var author$project$Main$init = F3(
	function (flags, url, key) {
		return _Utils_Tuple2(
			{
				Z: _List_fromArray(
					[
						rtfeldman$elm_css$Css$cursor(rtfeldman$elm_css$Css$crosshair),
						rtfeldman$elm_css$Css$overflow(rtfeldman$elm_css$Css$hidden),
						rtfeldman$elm_css$Css$overflowY(rtfeldman$elm_css$Css$hidden)
					]),
				aT: 0,
				b9: false,
				aU: 0,
				bd: key,
				C: false,
				aB: false,
				ag: false,
				W: 0,
				am: false,
				cF: url,
				bq: 0
			},
			A2(elm$core$Task$perform, author$project$Main$windwoResizeFromVp, elm$browser$Browser$Dom$getViewport));
	});
var author$project$Main$GetViewportClicked = {$: 12};
var elm$browser$Browser$Events$Window = 1;
var elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {cj: pids, cz: subs};
	});
var elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var elm$core$Dict$empty = elm$core$Dict$RBEmpty_elm_builtin;
var elm$browser$Browser$Events$init = elm$core$Task$succeed(
	A2(elm$browser$Browser$Events$State, _List_Nil, elm$core$Dict$empty));
var elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {b4: event, bd: key};
	});
var elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var elm$browser$Browser$Events$spawn = F3(
	function (router, key, _n0) {
		var node = _n0.a;
		var name = _n0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						elm$core$Platform$sendToSelf,
						router,
						A2(elm$browser$Browser$Events$Event, key, event));
				}));
	});
var elm$core$Dict$Black = 1;
var elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var elm$core$Basics$compare = _Utils_compare;
var elm$core$Dict$Red = 0;
var elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _n1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _n3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5(elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _n5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _n6 = left.d;
				var _n7 = _n6.a;
				var llK = _n6.b;
				var llV = _n6.c;
				var llLeft = _n6.d;
				var llRight = _n6.e;
				var lRight = left.e;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5(elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5(elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, elm$core$Dict$RBEmpty_elm_builtin, elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _n1 = A2(elm$core$Basics$compare, key, nKey);
			switch (_n1) {
				case 0:
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3(elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5(elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3(elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _n0 = A3(elm$core$Dict$insertHelp, key, value, dict);
		if ((_n0.$ === -1) && (!_n0.a)) {
			var _n1 = _n0.a;
			var k = _n0.b;
			var v = _n0.c;
			var l = _n0.d;
			var r = _n0.e;
			return A5(elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _n0;
			return x;
		}
	});
var elm$core$Dict$fromList = function (assocs) {
	return A3(
		elm$core$List$foldl,
		F2(
			function (_n0, dict) {
				var key = _n0.a;
				var value = _n0.b;
				return A3(elm$core$Dict$insert, key, value, dict);
			}),
		elm$core$Dict$empty,
		assocs);
};
var elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3(elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _n0) {
				stepState:
				while (true) {
					var list = _n0.a;
					var result = _n0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _n2 = list.a;
						var lKey = _n2.a;
						var lValue = _n2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_n0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_n0 = $temp$_n0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _n3 = A3(
			elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _n3.a;
		var intermediateResult = _n3.b;
		return A3(
			elm$core$List$foldl,
			F2(
				function (_n4, result) {
					var k = _n4.a;
					var v = _n4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3(elm$core$Dict$foldl, elm$core$Dict$insert, t2, t1);
	});
var elm$core$Process$kill = _Scheduler_kill;
var elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _n6) {
				var deads = _n6.a;
				var lives = _n6.b;
				var news = _n6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						elm$core$List$cons,
						A3(elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_n4, pid, _n5) {
				var deads = _n5.a;
				var lives = _n5.b;
				var news = _n5.c;
				return _Utils_Tuple3(
					A2(elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _n2, _n3) {
				var deads = _n3.a;
				var lives = _n3.b;
				var news = _n3.c;
				return _Utils_Tuple3(
					deads,
					A3(elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2(elm$core$List$map, elm$browser$Browser$Events$addKey, subs);
		var _n0 = A6(
			elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.cj,
			elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, elm$core$Dict$empty, _List_Nil));
		var deadPids = _n0.a;
		var livePids = _n0.b;
		var makeNewPids = _n0.c;
		return A2(
			elm$core$Task$andThen,
			function (pids) {
				return elm$core$Task$succeed(
					A2(
						elm$browser$Browser$Events$State,
						newSubs,
						A2(
							elm$core$Dict$union,
							livePids,
							elm$core$Dict$fromList(pids))));
			},
			A2(
				elm$core$Task$andThen,
				function (_n1) {
					return elm$core$Task$sequence(makeNewPids);
				},
				elm$core$Task$sequence(
					A2(elm$core$List$map, elm$core$Process$kill, deadPids))));
	});
var elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _n0 = f(mx);
		if (!_n0.$) {
			var x = _n0.a;
			return A2(elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			elm$core$List$foldr,
			elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _n0, state) {
		var key = _n0.bd;
		var event = _n0.b4;
		var toMessage = function (_n2) {
			var subKey = _n2.a;
			var _n3 = _n2.b;
			var node = _n3.a;
			var name = _n3.b;
			var decoder = _n3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : elm$core$Maybe$Nothing;
		};
		var messages = A2(elm$core$List$filterMap, toMessage, state.cz);
		return A2(
			elm$core$Task$andThen,
			function (_n1) {
				return elm$core$Task$succeed(state);
			},
			elm$core$Task$sequence(
				A2(
					elm$core$List$map,
					elm$core$Platform$sendToApp(router),
					messages)));
	});
var elm$browser$Browser$Events$subMap = F2(
	function (func, _n0) {
		var node = _n0.a;
		var name = _n0.b;
		var decoder = _n0.c;
		return A3(
			elm$browser$Browser$Events$MySub,
			node,
			name,
			A2(elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager(elm$browser$Browser$Events$init, elm$browser$Browser$Events$onEffects, elm$browser$Browser$Events$onSelfMsg, 0, elm$browser$Browser$Events$subMap);
var elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return elm$browser$Browser$Events$subscription(
			A3(elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var elm$json$Json$Decode$field = _Json_decodeField;
var elm$json$Json$Decode$int = _Json_decodeInt;
var elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			elm$json$Json$Decode$field,
			'target',
			A3(
				elm$json$Json$Decode$map2,
				func,
				A2(elm$json$Json$Decode$field, 'innerWidth', elm$json$Json$Decode$int),
				A2(elm$json$Json$Decode$field, 'innerHeight', elm$json$Json$Decode$int))));
};
var elm$core$Basics$always = F2(
	function (a, _n0) {
		return a;
	});
var elm$core$Platform$Sub$batch = _Platform_batch;
var elm$core$Platform$Sub$none = elm$core$Platform$Sub$batch(_List_Nil);
var elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$time$Time$State = F2(
	function (taggers, processes) {
		return {cm: processes, cA: taggers};
	});
var elm$time$Time$init = elm$core$Task$succeed(
	A2(elm$time$Time$State, elm$core$Dict$empty, elm$core$Dict$empty));
var elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _n1 = A2(elm$core$Basics$compare, targetKey, key);
				switch (_n1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var elm$time$Time$addMySub = F2(
	function (_n0, state) {
		var interval = _n0.a;
		var tagger = _n0.b;
		var _n1 = A2(elm$core$Dict$get, interval, state);
		if (_n1.$ === 1) {
			return A3(
				elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _n1.a;
			return A3(
				elm$core$Dict$insert,
				interval,
				A2(elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var elm$core$Process$spawn = _Scheduler_spawn;
var elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$time$Time$customZone = elm$time$Time$Zone;
var elm$time$Time$setInterval = _Time_setInterval;
var elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = elm$core$Process$spawn(
				A2(
					elm$time$Time$setInterval,
					interval,
					A2(elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					elm$time$Time$spawnHelp,
					router,
					rest,
					A3(elm$core$Dict$insert, interval, id, processes));
			};
			return A2(elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var elm$time$Time$onEffects = F3(
	function (router, subs, _n0) {
		var processes = _n0.cm;
		var rightStep = F3(
			function (_n6, id, _n7) {
				var spawns = _n7.a;
				var existing = _n7.b;
				var kills = _n7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						elm$core$Task$andThen,
						function (_n5) {
							return kills;
						},
						elm$core$Process$kill(id)));
			});
		var newTaggers = A3(elm$core$List$foldl, elm$time$Time$addMySub, elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _n4) {
				var spawns = _n4.a;
				var existing = _n4.b;
				var kills = _n4.c;
				return _Utils_Tuple3(
					A2(elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _n3) {
				var spawns = _n3.a;
				var existing = _n3.b;
				var kills = _n3.c;
				return _Utils_Tuple3(
					spawns,
					A3(elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _n1 = A6(
			elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				elm$core$Dict$empty,
				elm$core$Task$succeed(0)));
		var spawnList = _n1.a;
		var existingDict = _n1.b;
		var killTask = _n1.c;
		return A2(
			elm$core$Task$andThen,
			function (newProcesses) {
				return elm$core$Task$succeed(
					A2(elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				elm$core$Task$andThen,
				function (_n2) {
					return A3(elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var elm$time$Time$Posix = elm$core$Basics$identity;
var elm$time$Time$millisToPosix = elm$core$Basics$identity;
var elm$time$Time$now = _Time_now(elm$time$Time$millisToPosix);
var elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _n0 = A2(elm$core$Dict$get, interval, state.cA);
		if (_n0.$ === 1) {
			return elm$core$Task$succeed(state);
		} else {
			var taggers = _n0.a;
			var tellTaggers = function (time) {
				return elm$core$Task$sequence(
					A2(
						elm$core$List$map,
						function (tagger) {
							return A2(
								elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				elm$core$Task$andThen,
				function (_n1) {
					return elm$core$Task$succeed(state);
				},
				A2(elm$core$Task$andThen, tellTaggers, elm$time$Time$now));
		}
	});
var elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var elm$time$Time$subMap = F2(
	function (f, _n0) {
		var interval = _n0.a;
		var tagger = _n0.b;
		return A2(
			elm$time$Time$Every,
			interval,
			A2(elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager(elm$time$Time$init, elm$time$Time$onEffects, elm$time$Time$onSelfMsg, 0, elm$time$Time$subMap);
var elm$time$Time$subscription = _Platform_leaf('Time');
var elm$time$Time$every = F2(
	function (interval, tagger) {
		return elm$time$Time$subscription(
			A2(elm$time$Time$Every, interval, tagger));
	});
var author$project$Main$subscriptions = function (model) {
	return elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				elm$browser$Browser$Events$onResize(author$project$Main$WindowResize),
				model.ag ? elm$core$Platform$Sub$none : A2(
				elm$time$Time$every,
				200,
				elm$core$Basics$always(author$project$Main$GetViewportClicked))
			]));
};
var author$project$Main$DoNothing = {$: 6};
var author$project$Main$ShowToTopButton = function (a) {
	return {$: 11, a: a};
};
var elm_community$easing_functions$Ease$flip = F2(
	function (easing, time) {
		return 1 - easing(1 - time);
	});
var elm$core$Basics$pow = _Basics_pow;
var elm_community$easing_functions$Ease$inQuint = function (time) {
	return A2(elm$core$Basics$pow, time, 5);
};
var elm_community$easing_functions$Ease$outQuint = elm_community$easing_functions$Ease$flip(elm_community$easing_functions$Ease$inQuint);
var author$project$Main$defaultConfig = {b2: elm_community$easing_functions$Ease$outQuint, ch: 12, cw: 30};
var author$project$Main$fastConfig = {b2: elm_community$easing_functions$Ease$outQuint, ch: 0, cw: 200};
var elm$browser$Browser$Navigation$load = _Browser_load;
var elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var elm$core$Basics$ge = _Utils_ge;
var elm$core$Basics$not = _Basics_not;
var elm$core$Platform$Cmd$batch = _Platform_batch;
var elm$core$Platform$Cmd$none = elm$core$Platform$Cmd$batch(_List_Nil);
var elm$core$Task$onError = _Scheduler_onError;
var elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return elm$core$Task$command(
			A2(
				elm$core$Task$onError,
				A2(
					elm$core$Basics$composeL,
					A2(elm$core$Basics$composeL, elm$core$Task$succeed, resultToMessage),
					elm$core$Result$Err),
				A2(
					elm$core$Task$andThen,
					A2(
						elm$core$Basics$composeL,
						A2(elm$core$Basics$composeL, elm$core$Task$succeed, resultToMessage),
						elm$core$Result$Ok),
					task)));
	});
var elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + elm$core$String$fromInt(port_));
		}
	});
var elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var elm$url$Url$toString = function (url) {
	var http = function () {
		var _n0 = url.co;
		if (!_n0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		elm$url$Url$addPrefixed,
		'#',
		url.b6,
		A3(
			elm$url$Url$addPrefixed,
			'?',
			url.cp,
			_Utils_ap(
				A2(
					elm$url$Url$addPort,
					url.ck,
					_Utils_ap(http, url.b8)),
				url.c6)));
};
var elm$browser$Browser$Dom$getElement = _Browser_getElement;
var elm$browser$Browser$Dom$setViewport = _Browser_setViewport;
var elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var elm$core$Basics$negate = function (n) {
	return -n;
};
var elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var elm$core$Basics$round = _Basics_round;
var linuss$smooth_scroll$Internal$SmoothScroll$animationSteps = F4(
	function (speed, easing, start, stop) {
		var operator = (_Utils_cmp(start, stop) > 0) ? elm$core$Basics$sub : elm$core$Basics$add;
		var diff = elm$core$Basics$abs(start - stop);
		var frames = A2(
			elm$core$Basics$max,
			1,
			(elm$core$Basics$round(diff) / speed) | 0);
		var framesFloat = frames;
		var weights = A2(
			elm$core$List$map,
			function (i) {
				return easing(i / framesFloat);
			},
			A2(elm$core$List$range, 0, frames));
		return ((speed <= 0) || _Utils_eq(start, stop)) ? _List_Nil : A2(
			elm$core$List$map,
			function (weight) {
				return A2(operator, start, weight * diff);
			},
			weights);
	});
var linuss$smooth_scroll$SmoothScroll$scrollToWithOptions = F2(
	function (config, id) {
		var tasks = F2(
			function (from, to) {
				return elm$core$Task$sequence(
					A2(
						elm$core$List$map,
						elm$browser$Browser$Dom$setViewport(0),
						A4(linuss$smooth_scroll$Internal$SmoothScroll$animationSteps, config.cw, config.b2, from, to - config.ch)));
			});
		return A2(
			elm$core$Task$andThen,
			function (_n0) {
				var viewport = _n0.a.bO;
				var element = _n0.b.cR;
				return A2(tasks, viewport.dl, element.dl);
			},
			A3(
				elm$core$Task$map2,
				elm$core$Tuple$pair,
				elm$browser$Browser$Dom$getViewport,
				elm$browser$Browser$Dom$getElement(id)));
	});
var rtfeldman$elm_css$Css$height = rtfeldman$elm_css$Css$prop1('height');
var rtfeldman$elm_css$Css$none = {ao: 0, bV: 0, u: 0, a: 0, f: 0, cX: 0, cc: 0, bC: 0, aA: 0, ad: 0, K: 0, c: 0, b: 0, bE: 0, bi: 0, c7: 0, H: 0, bk: 0, da: 0, aH: 0, al: 0, A: 0, e: 0, dh: 0, M: 'none'};
var rtfeldman$elm_css$Css$pointerEvents = rtfeldman$elm_css$Css$prop1('pointer-events');
var rtfeldman$elm_css$Css$VhUnits = 0;
var elm$core$String$fromFloat = _String_fromNumber;
var rtfeldman$elm_css$Css$Internal$lengthConverter = F3(
	function (units, unitLabel, numericValue) {
		return {
			bP: 0,
			bX: 0,
			at: 0,
			o: 0,
			aV: 0,
			ay: 0,
			S: 0,
			az: 0,
			aA: 0,
			ad: 0,
			ae: 0,
			K: 0,
			V: numericValue,
			aI: 0,
			aK: unitLabel,
			a5: units,
			M: _Utils_ap(
				elm$core$String$fromFloat(numericValue),
				unitLabel)
		};
	});
var rtfeldman$elm_css$Css$vh = A2(rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'vh');
var author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{C: !model.C}),
					elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							Z: _List_fromArray(
								[
									rtfeldman$elm_css$Css$cursor(rtfeldman$elm_css$Css$crosshair),
									rtfeldman$elm_css$Css$overflowY(rtfeldman$elm_css$Css$hidden)
								]),
							ag: false,
							W: 0
						}),
					A2(
						elm$core$Task$attempt,
						elm$core$Basics$always(author$project$Main$DoNothing),
						A2(
							linuss$smooth_scroll$SmoothScroll$scrollToWithOptions,
							author$project$Main$fastConfig,
							elm$core$String$fromInt(model.W))));
			case 2:
				var projectId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							Z: _List_fromArray(
								[
									rtfeldman$elm_css$Css$cursor(rtfeldman$elm_css$Css$crosshair),
									rtfeldman$elm_css$Css$height(
									rtfeldman$elm_css$Css$vh(100)),
									rtfeldman$elm_css$Css$overflow(rtfeldman$elm_css$Css$hidden),
									rtfeldman$elm_css$Css$overflowY(rtfeldman$elm_css$Css$hidden),
									rtfeldman$elm_css$Css$pointerEvents(rtfeldman$elm_css$Css$none)
								]),
							ag: true,
							W: projectId,
							am: false
						}),
					elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							Z: _List_fromArray(
								[
									rtfeldman$elm_css$Css$cursor(rtfeldman$elm_css$Css$crosshair),
									rtfeldman$elm_css$Css$height(
									rtfeldman$elm_css$Css$vh(100)),
									rtfeldman$elm_css$Css$overflow(rtfeldman$elm_css$Css$hidden),
									rtfeldman$elm_css$Css$overflowY(rtfeldman$elm_css$Css$hidden),
									rtfeldman$elm_css$Css$pointerEvents(rtfeldman$elm_css$Css$none)
								]),
							C: false,
							ag: true,
							W: 7,
							am: false
						}),
					elm$core$Platform$Cmd$none);
			case 4:
				var pictureId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aU: pictureId}),
					elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aU: 0}),
					elm$core$Platform$Cmd$none);
			case 6:
				return _Utils_Tuple2(
					model,
					A2(elm$browser$Browser$Navigation$pushUrl, model.bd, '/'));
			case 7:
				var id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{C: false}),
					A2(
						elm$core$Task$attempt,
						elm$core$Basics$always(author$project$Main$DoNothing),
						A2(linuss$smooth_scroll$SmoothScroll$scrollToWithOptions, author$project$Main$defaultConfig, id)));
			case 8:
				var w = msg.a;
				var h = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aT: h, aB: w < 700, bq: w}),
					elm$core$Platform$Cmd$none);
			case 9:
				var urlRequest = msg.a;
				if (!urlRequest.$) {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						model,
						A2(
							elm$browser$Browser$Navigation$pushUrl,
							model.bd,
							elm$url$Url$toString(url)));
				} else {
					var href = urlRequest.a;
					return _Utils_Tuple2(
						model,
						elm$browser$Browser$Navigation$load(href));
				}
			case 10:
				var url = msg.a;
				var mod = (url.c6 === '/') ? _Utils_update(
					model,
					{
						Z: _List_fromArray(
							[
								rtfeldman$elm_css$Css$cursor(rtfeldman$elm_css$Css$crosshair),
								rtfeldman$elm_css$Css$overflowY(rtfeldman$elm_css$Css$hidden)
							]),
						ag: false,
						W: 0
					}) : model;
				return _Utils_Tuple2(mod, elm$core$Platform$Cmd$none);
			case 12:
				return _Utils_Tuple2(
					model,
					A2(elm$core$Task$perform, author$project$Main$ShowToTopButton, elm$browser$Browser$Dom$getViewport));
			default:
				var vp = msg.a;
				return (_Utils_cmp(
					elm$core$Basics$floor(vp.bO.dl),
					model.aT) > -1) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{am: true}),
					elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{am: false}),
					elm$core$Platform$Cmd$none);
		}
	});
var rtfeldman$elm_css$Css$cssFunction = F2(
	function (funcName, args) {
		return funcName + ('(' + (A2(elm$core$String$join, ', ', args) + ')'));
	});
var rtfeldman$elm_css$Css$rgb = F3(
	function (r, g, b) {
		return {
			s: 1,
			t: b,
			l: 0,
			x: g,
			z: r,
			M: A2(
				rtfeldman$elm_css$Css$cssFunction,
				'rgb',
				A2(
					elm$core$List$map,
					elm$core$String$fromInt,
					_List_fromArray(
						[r, g, b])))
		};
	});
var author$project$Color$black = A3(rtfeldman$elm_css$Css$rgb, 31, 31, 31);
var author$project$Color$white = A3(rtfeldman$elm_css$Css$rgb, 255, 255, 255);
var elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return elm$core$Maybe$Just(x);
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var rtfeldman$elm_css$Css$Internal$property = F2(
	function (key, value) {
		return rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var rtfeldman$elm_css$Css$Preprocess$ApplyStyles = function (a) {
	return {$: 6, a: a};
};
var rtfeldman$elm_css$Css$Internal$getOverloadedProperty = F3(
	function (functionName, desiredKey, style) {
		getOverloadedProperty:
		while (true) {
			switch (style.$) {
				case 0:
					var str = style.a;
					var key = A2(
						elm$core$Maybe$withDefault,
						'',
						elm$core$List$head(
							A2(elm$core$String$split, ':', str)));
					return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, key);
				case 1:
					var selector = style.a;
					return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-selector'));
				case 2:
					var combinator = style.a;
					return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-combinator'));
				case 3:
					var pseudoElement = style.a;
					return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-pseudo-element setter'));
				case 4:
					return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-media-query'));
				case 5:
					return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-keyframes'));
				default:
					if (!style.a.b) {
						return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-empty-Style'));
					} else {
						if (!style.a.b.b) {
							var _n1 = style.a;
							var only = _n1.a;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = only;
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						} else {
							var _n2 = style.a;
							var first = _n2.a;
							var rest = _n2.b;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = rtfeldman$elm_css$Css$Preprocess$ApplyStyles(rest);
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						}
					}
			}
		}
	});
var rtfeldman$elm_css$Css$Internal$IncompatibleUnits = 0;
var rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty = A3(rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '', 0);
var rtfeldman$elm_css$Css$alignItems = function (fn) {
	return A3(
		rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'alignItems',
		'align-items',
		fn(rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var rtfeldman$elm_css$Css$center = rtfeldman$elm_css$Css$prop1('center');
var rtfeldman$elm_css$Css$displayFlex = A2(rtfeldman$elm_css$Css$property, 'display', 'flex');
var rtfeldman$elm_css$Css$flexDirection = rtfeldman$elm_css$Css$prop1('flex-direction');
var rtfeldman$elm_css$Css$justifyContent = function (fn) {
	return A3(
		rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'justifyContent',
		'justify-content',
		fn(rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var rtfeldman$elm_css$Css$PxUnits = 0;
var rtfeldman$elm_css$Css$px = A2(rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'px');
var rtfeldman$elm_css$Css$row = {bA: 0, aS: 0, M: 'row'};
var rtfeldman$elm_css$Css$VwUnits = 0;
var rtfeldman$elm_css$Css$vw = A2(rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'vw');
var rtfeldman$elm_css$Css$width = rtfeldman$elm_css$Css$prop1('width');
var rtfeldman$elm_css$VirtualDom$Styled$Node = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var rtfeldman$elm_css$VirtualDom$Styled$node = rtfeldman$elm_css$VirtualDom$Styled$Node;
var rtfeldman$elm_css$Html$Styled$node = rtfeldman$elm_css$VirtualDom$Styled$node;
var rtfeldman$elm_css$Html$Styled$a = rtfeldman$elm_css$Html$Styled$node('a');
var rtfeldman$elm_css$Html$Styled$div = rtfeldman$elm_css$Html$Styled$node('div');
var rtfeldman$elm_css$Html$Styled$img = rtfeldman$elm_css$Html$Styled$node('img');
var elm$json$Json$Encode$string = _Json_wrap;
var elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var rtfeldman$elm_css$VirtualDom$Styled$Attribute = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var Skinney$murmur3$Murmur3$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {aq: charsProcessed, aw: hash, aj: seed, aF: shift};
	});
var Skinney$murmur3$Murmur3$c1 = 3432918353;
var Skinney$murmur3$Murmur3$c2 = 461845907;
var elm$core$Bitwise$and = _Bitwise_and;
var elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var Skinney$murmur3$Murmur3$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var elm$core$Bitwise$or = _Bitwise_or;
var Skinney$murmur3$Murmur3$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var elm$core$Basics$neq = _Utils_notEqual;
var elm$core$Bitwise$xor = _Bitwise_xor;
var Skinney$murmur3$Murmur3$finalize = function (data) {
	var acc = data.aw ? (data.aj ^ A2(
		Skinney$murmur3$Murmur3$multiplyBy,
		Skinney$murmur3$Murmur3$c2,
		A2(
			Skinney$murmur3$Murmur3$rotlBy,
			15,
			A2(Skinney$murmur3$Murmur3$multiplyBy, Skinney$murmur3$Murmur3$c1, data.aw)))) : data.aj;
	var h0 = acc ^ data.aq;
	var h1 = A2(Skinney$murmur3$Murmur3$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2(Skinney$murmur3$Murmur3$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var Skinney$murmur3$Murmur3$mix = F2(
	function (h1, k1) {
		return A2(
			Skinney$murmur3$Murmur3$multiplyBy,
			5,
			A2(
				Skinney$murmur3$Murmur3$rotlBy,
				13,
				h1 ^ A2(
					Skinney$murmur3$Murmur3$multiplyBy,
					Skinney$murmur3$Murmur3$c2,
					A2(
						Skinney$murmur3$Murmur3$rotlBy,
						15,
						A2(Skinney$murmur3$Murmur3$multiplyBy, Skinney$murmur3$Murmur3$c1, k1))))) + 3864292196;
	});
var Skinney$murmur3$Murmur3$hashFold = F2(
	function (c, data) {
		var res = data.aw | ((255 & elm$core$Char$toCode(c)) << data.aF);
		var _n0 = data.aF;
		if (_n0 === 24) {
			return {
				aq: data.aq + 1,
				aw: 0,
				aj: A2(Skinney$murmur3$Murmur3$mix, data.aj, res),
				aF: 0
			};
		} else {
			return {aq: data.aq + 1, aw: res, aj: data.aj, aF: data.aF + 8};
		}
	});
var elm$core$String$foldl = _String_foldl;
var Skinney$murmur3$Murmur3$hashString = F2(
	function (seed, str) {
		return Skinney$murmur3$Murmur3$finalize(
			A3(
				elm$core$String$foldl,
				Skinney$murmur3$Murmur3$hashFold,
				A4(Skinney$murmur3$Murmur3$HashData, 0, seed, 0, 0),
				str));
	});
var elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var elm$core$String$cons = _String_cons;
var rtfeldman$elm_css$Css$Preprocess$stylesheet = function (snippets) {
	return {bZ: elm$core$Maybe$Nothing, ca: _List_Nil, cg: _List_Nil, cv: snippets};
};
var elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3(elm$core$List$foldr, elm$core$List$cons, ys, xs);
		}
	});
var elm$core$List$concat = function (lists) {
	return A3(elm$core$List$foldr, elm$core$List$append, _List_Nil, lists);
};
var elm$core$List$concatMap = F2(
	function (f, list) {
		return elm$core$List$concat(
			A2(elm$core$List$map, f, list));
	});
var rtfeldman$elm_css$Css$Preprocess$unwrapSnippet = function (_n0) {
	var declarations = _n0;
	return declarations;
};
var elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return elm$core$Maybe$Just(xs);
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2(elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var elm$core$List$takeTailRec = F2(
	function (n, list) {
		return elm$core$List$reverse(
			A3(elm$core$List$takeReverse, n, list, _List_Nil));
	});
var elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _n0 = _Utils_Tuple2(n, list);
			_n0$1:
			while (true) {
				_n0$5:
				while (true) {
					if (!_n0.b.b) {
						return list;
					} else {
						if (_n0.b.b.b) {
							switch (_n0.a) {
								case 1:
									break _n0$1;
								case 2:
									var _n2 = _n0.b;
									var x = _n2.a;
									var _n3 = _n2.b;
									var y = _n3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_n0.b.b.b.b) {
										var _n4 = _n0.b;
										var x = _n4.a;
										var _n5 = _n4.b;
										var y = _n5.a;
										var _n6 = _n5.b;
										var z = _n6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _n0$5;
									}
								default:
									if (_n0.b.b.b.b && _n0.b.b.b.b.b) {
										var _n7 = _n0.b;
										var x = _n7.a;
										var _n8 = _n7.b;
										var y = _n8.a;
										var _n9 = _n8.b;
										var z = _n9.a;
										var _n10 = _n9.b;
										var w = _n10.a;
										var tl = _n10.b;
										return (ctr > 1000) ? A2(
											elm$core$List$cons,
											x,
											A2(
												elm$core$List$cons,
												y,
												A2(
													elm$core$List$cons,
													z,
													A2(
														elm$core$List$cons,
														w,
														A2(elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											elm$core$List$cons,
											x,
											A2(
												elm$core$List$cons,
												y,
												A2(
													elm$core$List$cons,
													z,
													A2(
														elm$core$List$cons,
														w,
														A3(elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _n0$5;
									}
							}
						} else {
							if (_n0.a === 1) {
								break _n0$1;
							} else {
								break _n0$5;
							}
						}
					}
				}
				return list;
			}
			var _n1 = _n0.b;
			var x = _n1.a;
			return _List_fromArray(
				[x]);
		}
	});
var elm$core$List$take = F2(
	function (n, list) {
		return A3(elm$core$List$takeFast, 0, n, list);
	});
var elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return elm$core$Maybe$Just(
				f(value));
		} else {
			return elm$core$Maybe$Nothing;
		}
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors = function (declarations) {
	collectSelectors:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			if (!declarations.a.$) {
				var _n1 = declarations.a.a;
				var firstSelector = _n1.a;
				var otherSelectors = _n1.b;
				var rest = declarations.b;
				return _Utils_ap(
					A2(elm$core$List$cons, firstSelector, otherSelectors),
					rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(rest));
			} else {
				var rest = declarations.b;
				var $temp$declarations = rest;
				declarations = $temp$declarations;
				continue collectSelectors;
			}
		}
	}
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$last = function (list) {
	last:
	while (true) {
		if (!list.b) {
			return elm$core$Maybe$Nothing;
		} else {
			if (!list.b.b) {
				var singleton = list.a;
				return elm$core$Maybe$Just(singleton);
			} else {
				var rest = list.b;
				var $temp$list = rest;
				list = $temp$list;
				continue last;
			}
		}
	}
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration = function (declarations) {
	lastDeclaration:
	while (true) {
		if (!declarations.b) {
			return elm$core$Maybe$Nothing;
		} else {
			if (!declarations.b.b) {
				var x = declarations.a;
				return elm$core$Maybe$Just(
					_List_fromArray(
						[x]));
			} else {
				var xs = declarations.b;
				var $temp$declarations = xs;
				declarations = $temp$declarations;
				continue lastDeclaration;
			}
		}
	}
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf = function (maybes) {
	oneOf:
	while (true) {
		if (!maybes.b) {
			return elm$core$Maybe$Nothing;
		} else {
			var maybe = maybes.a;
			var rest = maybes.b;
			if (maybe.$ === 1) {
				var $temp$maybes = rest;
				maybes = $temp$maybes;
				continue oneOf;
			} else {
				return maybe;
			}
		}
	}
};
var rtfeldman$elm_css$Css$Structure$FontFeatureValues = function (a) {
	return {$: 9, a: a};
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues = function (tuples) {
	var expandTuples = function (tuplesToExpand) {
		if (!tuplesToExpand.b) {
			return _List_Nil;
		} else {
			var properties = tuplesToExpand.a;
			var rest = tuplesToExpand.b;
			return A2(
				elm$core$List$cons,
				properties,
				expandTuples(rest));
		}
	};
	var newTuples = expandTuples(tuples);
	return _List_fromArray(
		[
			rtfeldman$elm_css$Css$Structure$FontFeatureValues(newTuples)
		]);
};
var rtfeldman$elm_css$Css$Structure$DocumentRule = F5(
	function (a, b, c, d, e) {
		return {$: 3, a: a, b: b, c: c, d: d, e: e};
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule = F5(
	function (str1, str2, str3, str4, declaration) {
		if (!declaration.$) {
			var structureStyleBlock = declaration.a;
			return A5(rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
		} else {
			return declaration;
		}
	});
var rtfeldman$elm_css$Css$Structure$MediaRule = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Structure$SupportsRule = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule = F2(
	function (mediaQueries, declaration) {
		switch (declaration.$) {
			case 0:
				var structureStyleBlock = declaration.a;
				return A2(
					rtfeldman$elm_css$Css$Structure$MediaRule,
					mediaQueries,
					_List_fromArray(
						[structureStyleBlock]));
			case 1:
				var newMediaQueries = declaration.a;
				var structureStyleBlocks = declaration.b;
				return A2(
					rtfeldman$elm_css$Css$Structure$MediaRule,
					_Utils_ap(mediaQueries, newMediaQueries),
					structureStyleBlocks);
			case 2:
				var str = declaration.a;
				var declarations = declaration.b;
				return A2(
					rtfeldman$elm_css$Css$Structure$SupportsRule,
					str,
					A2(
						elm$core$List$map,
						rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
						declarations));
			case 3:
				var str1 = declaration.a;
				var str2 = declaration.b;
				var str3 = declaration.c;
				var str4 = declaration.d;
				var structureStyleBlock = declaration.e;
				return A5(rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
			case 4:
				return declaration;
			case 5:
				return declaration;
			case 6:
				return declaration;
			case 7:
				return declaration;
			case 8:
				return declaration;
			default:
				return declaration;
		}
	});
var rtfeldman$elm_css$Css$Structure$CounterStyle = function (a) {
	return {$: 8, a: a};
};
var rtfeldman$elm_css$Css$Structure$FontFace = function (a) {
	return {$: 5, a: a};
};
var rtfeldman$elm_css$Css$Structure$Keyframes = function (a) {
	return {$: 6, a: a};
};
var rtfeldman$elm_css$Css$Structure$PageRule = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Structure$Selector = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var rtfeldman$elm_css$Css$Structure$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var rtfeldman$elm_css$Css$Structure$Viewport = function (a) {
	return {$: 7, a: a};
};
var rtfeldman$elm_css$Css$Structure$mapLast = F2(
	function (update, list) {
		if (!list.b) {
			return list;
		} else {
			if (!list.b.b) {
				var only = list.a;
				return _List_fromArray(
					[
						update(only)
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					elm$core$List$cons,
					first,
					A2(rtfeldman$elm_css$Css$Structure$mapLast, update, rest));
			}
		}
	});
var rtfeldman$elm_css$Css$Structure$withPropertyAppended = F2(
	function (property, _n0) {
		var firstSelector = _n0.a;
		var otherSelectors = _n0.b;
		var properties = _n0.c;
		return A3(
			rtfeldman$elm_css$Css$Structure$StyleBlock,
			firstSelector,
			otherSelectors,
			_Utils_ap(
				properties,
				_List_fromArray(
					[property])));
	});
var rtfeldman$elm_css$Css$Structure$appendProperty = F2(
	function (property, declarations) {
		if (!declarations.b) {
			return declarations;
		} else {
			if (!declarations.b.b) {
				switch (declarations.a.$) {
					case 0:
						var styleBlock = declarations.a.a;
						return _List_fromArray(
							[
								rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
								A2(rtfeldman$elm_css$Css$Structure$withPropertyAppended, property, styleBlock))
							]);
					case 1:
						var _n1 = declarations.a;
						var mediaQueries = _n1.a;
						var styleBlocks = _n1.b;
						return _List_fromArray(
							[
								A2(
								rtfeldman$elm_css$Css$Structure$MediaRule,
								mediaQueries,
								A2(
									rtfeldman$elm_css$Css$Structure$mapLast,
									rtfeldman$elm_css$Css$Structure$withPropertyAppended(property),
									styleBlocks))
							]);
					default:
						return declarations;
				}
			} else {
				var first = declarations.a;
				var rest = declarations.b;
				return A2(
					elm$core$List$cons,
					first,
					A2(rtfeldman$elm_css$Css$Structure$appendProperty, property, rest));
			}
		}
	});
var rtfeldman$elm_css$Css$Structure$appendToLastSelector = F2(
	function (f, styleBlock) {
		if (!styleBlock.b.b) {
			var only = styleBlock.a;
			var properties = styleBlock.c;
			return _List_fromArray(
				[
					A3(rtfeldman$elm_css$Css$Structure$StyleBlock, only, _List_Nil, properties),
					A3(
					rtfeldman$elm_css$Css$Structure$StyleBlock,
					f(only),
					_List_Nil,
					_List_Nil)
				]);
		} else {
			var first = styleBlock.a;
			var rest = styleBlock.b;
			var properties = styleBlock.c;
			var newRest = A2(elm$core$List$map, f, rest);
			var newFirst = f(first);
			return _List_fromArray(
				[
					A3(rtfeldman$elm_css$Css$Structure$StyleBlock, first, rest, properties),
					A3(rtfeldman$elm_css$Css$Structure$StyleBlock, newFirst, newRest, _List_Nil)
				]);
		}
	});
var rtfeldman$elm_css$Css$Structure$applyPseudoElement = F2(
	function (pseudo, _n0) {
		var sequence = _n0.a;
		var selectors = _n0.b;
		return A3(
			rtfeldman$elm_css$Css$Structure$Selector,
			sequence,
			selectors,
			elm$core$Maybe$Just(pseudo));
	});
var rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector = F2(
	function (pseudo, styleBlock) {
		return A2(
			rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			rtfeldman$elm_css$Css$Structure$applyPseudoElement(pseudo),
			styleBlock);
	});
var rtfeldman$elm_css$Css$Structure$CustomSelector = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Structure$TypeSelectorSequence = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence = function (a) {
	return {$: 1, a: a};
};
var rtfeldman$elm_css$Css$Structure$appendRepeatable = F2(
	function (selector, sequence) {
		switch (sequence.$) {
			case 0:
				var typeSelector = sequence.a;
				var list = sequence.b;
				return A2(
					rtfeldman$elm_css$Css$Structure$TypeSelectorSequence,
					typeSelector,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			case 1:
				var list = sequence.a;
				return rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			default:
				var str = sequence.a;
				var list = sequence.b;
				return A2(
					rtfeldman$elm_css$Css$Structure$CustomSelector,
					str,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
		}
	});
var rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator = F2(
	function (selector, list) {
		if (!list.b) {
			return _List_Nil;
		} else {
			if (!list.b.b) {
				var _n1 = list.a;
				var combinator = _n1.a;
				var sequence = _n1.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						combinator,
						A2(rtfeldman$elm_css$Css$Structure$appendRepeatable, selector, sequence))
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					elm$core$List$cons,
					first,
					A2(rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, selector, rest));
			}
		}
	});
var rtfeldman$elm_css$Css$Structure$appendRepeatableSelector = F2(
	function (repeatableSimpleSelector, selector) {
		if (!selector.b.b) {
			var sequence = selector.a;
			var pseudoElement = selector.c;
			return A3(
				rtfeldman$elm_css$Css$Structure$Selector,
				A2(rtfeldman$elm_css$Css$Structure$appendRepeatable, repeatableSimpleSelector, sequence),
				_List_Nil,
				pseudoElement);
		} else {
			var firstSelector = selector.a;
			var tuples = selector.b;
			var pseudoElement = selector.c;
			return A3(
				rtfeldman$elm_css$Css$Structure$Selector,
				firstSelector,
				A2(rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, repeatableSimpleSelector, tuples),
				pseudoElement);
		}
	});
var rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector = F2(
	function (selector, styleBlock) {
		return A2(
			rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			rtfeldman$elm_css$Css$Structure$appendRepeatableSelector(selector),
			styleBlock);
	});
var rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock = F2(
	function (update, declarations) {
		_n0$12:
		while (true) {
			if (!declarations.b) {
				return declarations;
			} else {
				if (!declarations.b.b) {
					switch (declarations.a.$) {
						case 0:
							var styleBlock = declarations.a.a;
							return A2(
								elm$core$List$map,
								rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration,
								update(styleBlock));
						case 1:
							if (declarations.a.b.b) {
								if (!declarations.a.b.b.b) {
									var _n1 = declarations.a;
									var mediaQueries = _n1.a;
									var _n2 = _n1.b;
									var styleBlock = _n2.a;
									return _List_fromArray(
										[
											A2(
											rtfeldman$elm_css$Css$Structure$MediaRule,
											mediaQueries,
											update(styleBlock))
										]);
								} else {
									var _n3 = declarations.a;
									var mediaQueries = _n3.a;
									var _n4 = _n3.b;
									var first = _n4.a;
									var rest = _n4.b;
									var _n5 = A2(
										rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock,
										update,
										_List_fromArray(
											[
												A2(rtfeldman$elm_css$Css$Structure$MediaRule, mediaQueries, rest)
											]));
									if ((_n5.b && (_n5.a.$ === 1)) && (!_n5.b.b)) {
										var _n6 = _n5.a;
										var newMediaQueries = _n6.a;
										var newStyleBlocks = _n6.b;
										return _List_fromArray(
											[
												A2(
												rtfeldman$elm_css$Css$Structure$MediaRule,
												newMediaQueries,
												A2(elm$core$List$cons, first, newStyleBlocks))
											]);
									} else {
										var newDeclarations = _n5;
										return newDeclarations;
									}
								}
							} else {
								break _n0$12;
							}
						case 2:
							var _n7 = declarations.a;
							var str = _n7.a;
							var nestedDeclarations = _n7.b;
							return _List_fromArray(
								[
									A2(
									rtfeldman$elm_css$Css$Structure$SupportsRule,
									str,
									A2(rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, nestedDeclarations))
								]);
						case 3:
							var _n8 = declarations.a;
							var str1 = _n8.a;
							var str2 = _n8.b;
							var str3 = _n8.c;
							var str4 = _n8.d;
							var styleBlock = _n8.e;
							return A2(
								elm$core$List$map,
								A4(rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4),
								update(styleBlock));
						case 4:
							var _n9 = declarations.a;
							return declarations;
						case 5:
							return declarations;
						case 6:
							return declarations;
						case 7:
							return declarations;
						case 8:
							return declarations;
						default:
							return declarations;
					}
				} else {
					break _n0$12;
				}
			}
		}
		var first = declarations.a;
		var rest = declarations.b;
		return A2(
			elm$core$List$cons,
			first,
			A2(rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, rest));
	});
var rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule = F2(
	function (mediaQueries, declaration) {
		if (!declaration.$) {
			var styleBlock = declaration.a;
			return A2(
				rtfeldman$elm_css$Css$Structure$MediaRule,
				mediaQueries,
				_List_fromArray(
					[styleBlock]));
		} else {
			return declaration;
		}
	});
var rtfeldman$elm_css$Hash$murmurSeed = 15739;
var elm$core$String$fromList = _String_fromList;
var elm$core$Basics$modBy = _Basics_modBy;
var rtfeldman$elm_hex$Hex$unsafeToDigit = function (num) {
	unsafeToDigit:
	while (true) {
		switch (num) {
			case 0:
				return '0';
			case 1:
				return '1';
			case 2:
				return '2';
			case 3:
				return '3';
			case 4:
				return '4';
			case 5:
				return '5';
			case 6:
				return '6';
			case 7:
				return '7';
			case 8:
				return '8';
			case 9:
				return '9';
			case 10:
				return 'a';
			case 11:
				return 'b';
			case 12:
				return 'c';
			case 13:
				return 'd';
			case 14:
				return 'e';
			case 15:
				return 'f';
			default:
				var $temp$num = num;
				num = $temp$num;
				continue unsafeToDigit;
		}
	}
};
var rtfeldman$elm_hex$Hex$unsafePositiveToDigits = F2(
	function (digits, num) {
		unsafePositiveToDigits:
		while (true) {
			if (num < 16) {
				return A2(
					elm$core$List$cons,
					rtfeldman$elm_hex$Hex$unsafeToDigit(num),
					digits);
			} else {
				var $temp$digits = A2(
					elm$core$List$cons,
					rtfeldman$elm_hex$Hex$unsafeToDigit(
						A2(elm$core$Basics$modBy, 16, num)),
					digits),
					$temp$num = (num / 16) | 0;
				digits = $temp$digits;
				num = $temp$num;
				continue unsafePositiveToDigits;
			}
		}
	});
var rtfeldman$elm_hex$Hex$toString = function (num) {
	return elm$core$String$fromList(
		(num < 0) ? A2(
			elm$core$List$cons,
			'-',
			A2(rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, -num)) : A2(rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, num));
};
var rtfeldman$elm_css$Hash$fromString = function (str) {
	return A2(
		elm$core$String$cons,
		'_',
		rtfeldman$elm_hex$Hex$toString(
			A2(Skinney$murmur3$Murmur3$hashString, rtfeldman$elm_css$Hash$murmurSeed, str)));
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast = F4(
	function (nestedStyles, rest, f, declarations) {
		var withoutParent = function (decls) {
			return A2(
				elm$core$Maybe$withDefault,
				_List_Nil,
				elm$core$List$tail(decls));
		};
		var nextResult = A2(
			rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
			rest,
			A2(
				elm$core$Maybe$withDefault,
				_List_Nil,
				rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		var newDeclarations = function () {
			var _n14 = _Utils_Tuple2(
				elm$core$List$head(nextResult),
				rtfeldman$elm_css$Css$Preprocess$Resolve$last(declarations));
			if ((!_n14.a.$) && (!_n14.b.$)) {
				var nextResultParent = _n14.a.a;
				var originalParent = _n14.b.a;
				return _Utils_ap(
					A2(
						elm$core$List$take,
						elm$core$List$length(declarations) - 1,
						declarations),
					_List_fromArray(
						[
							(!_Utils_eq(originalParent, nextResultParent)) ? nextResultParent : originalParent
						]));
			} else {
				return declarations;
			}
		}();
		var insertStylesToNestedDecl = function (lastDecl) {
			return elm$core$List$concat(
				A2(
					rtfeldman$elm_css$Css$Structure$mapLast,
					rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles(nestedStyles),
					A2(
						elm$core$List$map,
						elm$core$List$singleton,
						A2(rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, f, lastDecl))));
		};
		var initialResult = A2(
			elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				elm$core$Maybe$map,
				insertStylesToNestedDecl,
				rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		return _Utils_ap(
			newDeclarations,
			_Utils_ap(
				withoutParent(initialResult),
				withoutParent(nextResult)));
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles = F2(
	function (styles, declarations) {
		if (!styles.b) {
			return declarations;
		} else {
			switch (styles.a.$) {
				case 0:
					var property = styles.a.a;
					var rest = styles.b;
					return A2(
						rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2(rtfeldman$elm_css$Css$Structure$appendProperty, property, declarations));
				case 1:
					var _n4 = styles.a;
					var selector = _n4.a;
					var nestedStyles = _n4.b;
					var rest = styles.b;
					return A4(
						rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector(selector),
						declarations);
				case 2:
					var _n5 = styles.a;
					var selectorCombinator = _n5.a;
					var snippets = _n5.b;
					var rest = styles.b;
					var chain = F2(
						function (_n9, _n10) {
							var originalSequence = _n9.a;
							var originalTuples = _n9.b;
							var originalPseudoElement = _n9.c;
							var newSequence = _n10.a;
							var newTuples = _n10.b;
							var newPseudoElement = _n10.c;
							return A3(
								rtfeldman$elm_css$Css$Structure$Selector,
								originalSequence,
								_Utils_ap(
									originalTuples,
									A2(
										elm$core$List$cons,
										_Utils_Tuple2(selectorCombinator, newSequence),
										newTuples)),
								rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf(
									_List_fromArray(
										[newPseudoElement, originalPseudoElement])));
						});
					var expandDeclaration = function (declaration) {
						switch (declaration.$) {
							case 0:
								var _n7 = declaration.a;
								var firstSelector = _n7.a;
								var otherSelectors = _n7.b;
								var nestedStyles = _n7.c;
								var newSelectors = A2(
									elm$core$List$concatMap,
									function (originalSelector) {
										return A2(
											elm$core$List$map,
											chain(originalSelector),
											A2(elm$core$List$cons, firstSelector, otherSelectors));
									},
									rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations));
								var newDeclarations = function () {
									if (!newSelectors.b) {
										return _List_Nil;
									} else {
										var first = newSelectors.a;
										var remainder = newSelectors.b;
										return _List_fromArray(
											[
												rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
												A3(rtfeldman$elm_css$Css$Structure$StyleBlock, first, remainder, _List_Nil))
											]);
									}
								}();
								return A2(rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, nestedStyles, newDeclarations);
							case 1:
								var mediaQueries = declaration.a;
								var styleBlocks = declaration.b;
								return A2(rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
							case 2:
								var str = declaration.a;
								var otherSnippets = declaration.b;
								return A2(rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, otherSnippets);
							case 3:
								var str1 = declaration.a;
								var str2 = declaration.b;
								var str3 = declaration.c;
								var str4 = declaration.d;
								var styleBlock = declaration.e;
								return A2(
									elm$core$List$map,
									A4(rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
									rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
							case 4:
								var str = declaration.a;
								var properties = declaration.b;
								return _List_fromArray(
									[
										A2(rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
									]);
							case 5:
								var properties = declaration.a;
								return _List_fromArray(
									[
										rtfeldman$elm_css$Css$Structure$FontFace(properties)
									]);
							case 6:
								var properties = declaration.a;
								return _List_fromArray(
									[
										rtfeldman$elm_css$Css$Structure$Viewport(properties)
									]);
							case 7:
								var properties = declaration.a;
								return _List_fromArray(
									[
										rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
									]);
							default:
								var tuples = declaration.a;
								return rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
						}
					};
					return elm$core$List$concat(
						_Utils_ap(
							_List_fromArray(
								[
									A2(rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations)
								]),
							A2(
								elm$core$List$map,
								expandDeclaration,
								A2(elm$core$List$concatMap, rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets))));
				case 3:
					var _n11 = styles.a;
					var pseudoElement = _n11.a;
					var nestedStyles = _n11.b;
					var rest = styles.b;
					return A4(
						rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector(pseudoElement),
						declarations);
				case 5:
					var str = styles.a.a;
					var rest = styles.b;
					var name = rtfeldman$elm_css$Hash$fromString(str);
					var newProperty = 'animation-name:' + name;
					var newDeclarations = A2(
						rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2(rtfeldman$elm_css$Css$Structure$appendProperty, newProperty, declarations));
					return A2(
						elm$core$List$append,
						newDeclarations,
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$Structure$Keyframes(
								{cO: str, c2: name})
							]));
				case 4:
					var _n12 = styles.a;
					var mediaQueries = _n12.a;
					var nestedStyles = _n12.b;
					var rest = styles.b;
					var extraDeclarations = function () {
						var _n13 = rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations);
						if (!_n13.b) {
							return _List_Nil;
						} else {
							var firstSelector = _n13.a;
							var otherSelectors = _n13.b;
							return A2(
								elm$core$List$map,
								rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule(mediaQueries),
								A2(
									rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
									nestedStyles,
									elm$core$List$singleton(
										rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
											A3(rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil)))));
						}
					}();
					return _Utils_ap(
						A2(rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations),
						extraDeclarations);
				default:
					var otherStyles = styles.a.a;
					var rest = styles.b;
					return A2(
						rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						_Utils_ap(otherStyles, rest),
						declarations);
			}
		}
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock = function (_n2) {
	var firstSelector = _n2.a;
	var otherSelectors = _n2.b;
	var styles = _n2.c;
	return A2(
		rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
		styles,
		_List_fromArray(
			[
				rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
				A3(rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil))
			]));
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$extract = function (snippetDeclarations) {
	if (!snippetDeclarations.b) {
		return _List_Nil;
	} else {
		var first = snippetDeclarations.a;
		var rest = snippetDeclarations.b;
		return _Utils_ap(
			rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations(first),
			rtfeldman$elm_css$Css$Preprocess$Resolve$extract(rest));
	}
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule = F2(
	function (mediaQueries, styleBlocks) {
		var handleStyleBlock = function (styleBlock) {
			return A2(
				elm$core$List$map,
				rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
				rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		};
		return A2(elm$core$List$concatMap, handleStyleBlock, styleBlocks);
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule = F2(
	function (str, snippets) {
		var declarations = rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
			A2(elm$core$List$concatMap, rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
		return _List_fromArray(
			[
				A2(rtfeldman$elm_css$Css$Structure$SupportsRule, str, declarations)
			]);
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations = function (snippetDeclaration) {
	switch (snippetDeclaration.$) {
		case 0:
			var styleBlock = snippetDeclaration.a;
			return rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
		case 1:
			var mediaQueries = snippetDeclaration.a;
			var styleBlocks = snippetDeclaration.b;
			return A2(rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
		case 2:
			var str = snippetDeclaration.a;
			var snippets = snippetDeclaration.b;
			return A2(rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, snippets);
		case 3:
			var str1 = snippetDeclaration.a;
			var str2 = snippetDeclaration.b;
			var str3 = snippetDeclaration.c;
			var str4 = snippetDeclaration.d;
			var styleBlock = snippetDeclaration.e;
			return A2(
				elm$core$List$map,
				A4(rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
				rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		case 4:
			var str = snippetDeclaration.a;
			var properties = snippetDeclaration.b;
			return _List_fromArray(
				[
					A2(rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
				]);
		case 5:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					rtfeldman$elm_css$Css$Structure$FontFace(properties)
				]);
		case 6:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					rtfeldman$elm_css$Css$Structure$Viewport(properties)
				]);
		case 7:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
				]);
		default:
			var tuples = snippetDeclaration.a;
			return rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
	}
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure = function (_n0) {
	var charset = _n0.bZ;
	var imports = _n0.ca;
	var namespaces = _n0.cg;
	var snippets = _n0.cv;
	var declarations = rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
		A2(elm$core$List$concatMap, rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
	return {bZ: charset, cP: declarations, ca: imports, cg: namespaces};
};
var elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			elm$core$List$any,
			A2(elm$core$Basics$composeL, elm$core$Basics$not, isOkay),
			list);
	});
var rtfeldman$elm_css$Css$Structure$compactHelp = F2(
	function (declaration, _n0) {
		var keyframesByName = _n0.a;
		var declarations = _n0.b;
		switch (declaration.$) {
			case 0:
				var _n2 = declaration.a;
				var properties = _n2.c;
				return elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 1:
				var styleBlocks = declaration.b;
				return A2(
					elm$core$List$all,
					function (_n3) {
						var properties = _n3.c;
						return elm$core$List$isEmpty(properties);
					},
					styleBlocks) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 2:
				var otherDeclarations = declaration.b;
				return elm$core$List$isEmpty(otherDeclarations) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 3:
				return _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 4:
				var properties = declaration.b;
				return elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 5:
				var properties = declaration.a;
				return elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 6:
				var record = declaration.a;
				return elm$core$String$isEmpty(record.cO) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					A3(elm$core$Dict$insert, record.c2, record.cO, keyframesByName),
					declarations);
			case 7:
				var properties = declaration.a;
				return elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 8:
				var properties = declaration.a;
				return elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			default:
				var tuples = declaration.a;
				return A2(
					elm$core$List$all,
					function (_n4) {
						var properties = _n4.b;
						return elm$core$List$isEmpty(properties);
					},
					tuples) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
		}
	});
var rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations = F2(
	function (keyframesByName, compactedDeclarations) {
		return A2(
			elm$core$List$append,
			A2(
				elm$core$List$map,
				function (_n0) {
					var name = _n0.a;
					var decl = _n0.b;
					return rtfeldman$elm_css$Css$Structure$Keyframes(
						{cO: decl, c2: name});
				},
				elm$core$Dict$toList(keyframesByName)),
			compactedDeclarations);
	});
var rtfeldman$elm_css$Css$Structure$compactStylesheet = function (_n0) {
	var charset = _n0.bZ;
	var imports = _n0.ca;
	var namespaces = _n0.cg;
	var declarations = _n0.cP;
	var _n1 = A3(
		elm$core$List$foldr,
		rtfeldman$elm_css$Css$Structure$compactHelp,
		_Utils_Tuple2(elm$core$Dict$empty, _List_Nil),
		declarations);
	var keyframesByName = _n1.a;
	var compactedDeclarations = _n1.b;
	var finalDeclarations = A2(rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations, keyframesByName, compactedDeclarations);
	return {bZ: charset, cP: finalDeclarations, ca: imports, cg: namespaces};
};
var elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2(elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var rtfeldman$elm_css$Css$Structure$Output$charsetToString = function (charset) {
	return A2(
		elm$core$Maybe$withDefault,
		'',
		A2(
			elm$core$Maybe$map,
			function (str) {
				return '@charset \"' + (str + '\"');
			},
			charset));
};
var rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString = function (expression) {
	return '(' + (expression.b5 + (A2(
		elm$core$Maybe$withDefault,
		'',
		A2(
			elm$core$Maybe$map,
			elm$core$Basics$append(': '),
			expression.M)) + ')'));
};
var rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString = function (mediaType) {
	switch (mediaType) {
		case 0:
			return 'print';
		case 1:
			return 'screen';
		default:
			return 'speech';
	}
};
var rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString = function (mediaQuery) {
	var prefixWith = F3(
		function (str, mediaType, expressions) {
			return str + (' ' + A2(
				elm$core$String$join,
				' and ',
				A2(
					elm$core$List$cons,
					rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString(mediaType),
					A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions))));
		});
	switch (mediaQuery.$) {
		case 0:
			var expressions = mediaQuery.a;
			return A2(
				elm$core$String$join,
				' and ',
				A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions));
		case 1:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'only', mediaType, expressions);
		case 2:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'not', mediaType, expressions);
		default:
			var str = mediaQuery.a;
			return str;
	}
};
var rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString = F2(
	function (name, mediaQuery) {
		return '@import \"' + (name + (rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString(mediaQuery) + '\"'));
	});
var rtfeldman$elm_css$Css$Structure$Output$importToString = function (_n0) {
	var name = _n0.a;
	var mediaQueries = _n0.b;
	return A2(
		elm$core$String$join,
		'\n',
		A2(
			elm$core$List$map,
			rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString(name),
			mediaQueries));
};
var rtfeldman$elm_css$Css$Structure$Output$namespaceToString = function (_n0) {
	var prefix = _n0.a;
	var str = _n0.b;
	return '@namespace ' + (prefix + ('\"' + (str + '\"')));
};
var rtfeldman$elm_css$Css$Structure$Output$spaceIndent = '    ';
var rtfeldman$elm_css$Css$Structure$Output$indent = function (str) {
	return _Utils_ap(rtfeldman$elm_css$Css$Structure$Output$spaceIndent, str);
};
var rtfeldman$elm_css$Css$Structure$Output$noIndent = '';
var rtfeldman$elm_css$Css$Structure$Output$emitProperty = function (str) {
	return str + ';';
};
var rtfeldman$elm_css$Css$Structure$Output$emitProperties = function (properties) {
	return A2(
		elm$core$String$join,
		'\n',
		A2(
			elm$core$List$map,
			A2(elm$core$Basics$composeL, rtfeldman$elm_css$Css$Structure$Output$indent, rtfeldman$elm_css$Css$Structure$Output$emitProperty),
			properties));
};
var elm$core$String$append = _String_append;
var rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString = function (_n0) {
	var str = _n0;
	return '::' + str;
};
var rtfeldman$elm_css$Css$Structure$Output$combinatorToString = function (combinator) {
	switch (combinator) {
		case 0:
			return '+';
		case 1:
			return '~';
		case 2:
			return '>';
		default:
			return '';
	}
};
var rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString = function (repeatableSimpleSelector) {
	switch (repeatableSimpleSelector.$) {
		case 0:
			var str = repeatableSimpleSelector.a;
			return '.' + str;
		case 1:
			var str = repeatableSimpleSelector.a;
			return '#' + str;
		case 2:
			var str = repeatableSimpleSelector.a;
			return ':' + str;
		default:
			var str = repeatableSimpleSelector.a;
			return '[' + (str + ']');
	}
};
var rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString = function (simpleSelectorSequence) {
	switch (simpleSelectorSequence.$) {
		case 0:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				elm$core$String$join,
				'',
				A2(
					elm$core$List$cons,
					str,
					A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
		case 1:
			var repeatableSimpleSelectors = simpleSelectorSequence.a;
			return elm$core$List$isEmpty(repeatableSimpleSelectors) ? '*' : A2(
				elm$core$String$join,
				'',
				A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors));
		default:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				elm$core$String$join,
				'',
				A2(
					elm$core$List$cons,
					str,
					A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
	}
};
var rtfeldman$elm_css$Css$Structure$Output$selectorChainToString = function (_n0) {
	var combinator = _n0.a;
	var sequence = _n0.b;
	return A2(
		elm$core$String$join,
		' ',
		_List_fromArray(
			[
				rtfeldman$elm_css$Css$Structure$Output$combinatorToString(combinator),
				rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(sequence)
			]));
};
var rtfeldman$elm_css$Css$Structure$Output$selectorToString = function (_n0) {
	var simpleSelectorSequence = _n0.a;
	var chain = _n0.b;
	var pseudoElement = _n0.c;
	var segments = A2(
		elm$core$List$cons,
		rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(simpleSelectorSequence),
		A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$selectorChainToString, chain));
	var pseudoElementsString = A2(
		elm$core$String$join,
		'',
		_List_fromArray(
			[
				A2(
				elm$core$Maybe$withDefault,
				'',
				A2(elm$core$Maybe$map, rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString, pseudoElement))
			]));
	return A2(
		elm$core$String$append,
		A2(
			elm$core$String$join,
			' ',
			A2(
				elm$core$List$filter,
				A2(elm$core$Basics$composeL, elm$core$Basics$not, elm$core$String$isEmpty),
				segments)),
		pseudoElementsString);
};
var rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock = F2(
	function (indentLevel, _n0) {
		var firstSelector = _n0.a;
		var otherSelectors = _n0.b;
		var properties = _n0.c;
		var selectorStr = A2(
			elm$core$String$join,
			', ',
			A2(
				elm$core$List$map,
				rtfeldman$elm_css$Css$Structure$Output$selectorToString,
				A2(elm$core$List$cons, firstSelector, otherSelectors)));
		return A2(
			elm$core$String$join,
			'',
			_List_fromArray(
				[
					selectorStr,
					' {\n',
					indentLevel,
					rtfeldman$elm_css$Css$Structure$Output$emitProperties(properties),
					'\n',
					indentLevel,
					'}'
				]));
	});
var rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration = function (decl) {
	switch (decl.$) {
		case 0:
			var styleBlock = decl.a;
			return A2(rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock, rtfeldman$elm_css$Css$Structure$Output$noIndent, styleBlock);
		case 1:
			var mediaQueries = decl.a;
			var styleBlocks = decl.b;
			var query = A2(
				elm$core$String$join,
				',\n',
				A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString, mediaQueries));
			var blocks = A2(
				elm$core$String$join,
				'\n\n',
				A2(
					elm$core$List$map,
					A2(
						elm$core$Basics$composeL,
						rtfeldman$elm_css$Css$Structure$Output$indent,
						rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock(rtfeldman$elm_css$Css$Structure$Output$spaceIndent)),
					styleBlocks));
			return '@media ' + (query + (' {\n' + (blocks + '\n}')));
		case 2:
			return 'TODO';
		case 3:
			return 'TODO';
		case 4:
			return 'TODO';
		case 5:
			return 'TODO';
		case 6:
			var name = decl.a.c2;
			var declaration = decl.a.cO;
			return '@keyframes ' + (name + (' {\n' + (declaration + '\n}')));
		case 7:
			return 'TODO';
		case 8:
			return 'TODO';
		default:
			return 'TODO';
	}
};
var rtfeldman$elm_css$Css$Structure$Output$prettyPrint = function (_n0) {
	var charset = _n0.bZ;
	var imports = _n0.ca;
	var namespaces = _n0.cg;
	var declarations = _n0.cP;
	return A2(
		elm$core$String$join,
		'\n\n',
		A2(
			elm$core$List$filter,
			A2(elm$core$Basics$composeL, elm$core$Basics$not, elm$core$String$isEmpty),
			_List_fromArray(
				[
					rtfeldman$elm_css$Css$Structure$Output$charsetToString(charset),
					A2(
					elm$core$String$join,
					'\n',
					A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$importToString, imports)),
					A2(
					elm$core$String$join,
					'\n',
					A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$namespaceToString, namespaces)),
					A2(
					elm$core$String$join,
					'\n\n',
					A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration, declarations))
				])));
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp = function (sheet) {
	return rtfeldman$elm_css$Css$Structure$Output$prettyPrint(
		rtfeldman$elm_css$Css$Structure$compactStylesheet(
			rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure(sheet)));
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$compile = function (styles) {
	return A2(
		elm$core$String$join,
		'\n\n',
		A2(elm$core$List$map, rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp, styles));
};
var rtfeldman$elm_css$Css$Preprocess$Snippet = elm$core$Basics$identity;
var rtfeldman$elm_css$Css$Preprocess$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var rtfeldman$elm_css$VirtualDom$Styled$makeSnippet = F2(
	function (styles, sequence) {
		var selector = A3(rtfeldman$elm_css$Css$Structure$Selector, sequence, _List_Nil, elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration(
				A3(rtfeldman$elm_css$Css$Preprocess$StyleBlock, selector, _List_Nil, styles))
			]);
	});
var rtfeldman$elm_css$VirtualDom$Styled$murmurSeed = 15739;
var rtfeldman$elm_css$VirtualDom$Styled$getClassname = function (styles) {
	return elm$core$List$isEmpty(styles) ? 'unstyled' : A2(
		elm$core$String$cons,
		'_',
		rtfeldman$elm_hex$Hex$toString(
			A2(
				Skinney$murmur3$Murmur3$hashString,
				rtfeldman$elm_css$VirtualDom$Styled$murmurSeed,
				rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
					elm$core$List$singleton(
						rtfeldman$elm_css$Css$Preprocess$stylesheet(
							elm$core$List$singleton(
								A2(
									rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
									styles,
									rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(_List_Nil)))))))));
};
var rtfeldman$elm_css$Html$Styled$Internal$css = function (styles) {
	var classname = rtfeldman$elm_css$VirtualDom$Styled$getClassname(styles);
	var classProperty = A2(
		elm$virtual_dom$VirtualDom$property,
		'className',
		elm$json$Json$Encode$string(classname));
	return A3(rtfeldman$elm_css$VirtualDom$Styled$Attribute, classProperty, styles, classname);
};
var rtfeldman$elm_css$Html$Styled$Attributes$css = rtfeldman$elm_css$Html$Styled$Internal$css;
var rtfeldman$elm_css$VirtualDom$Styled$property = F2(
	function (key, value) {
		return A3(
			rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2(elm$virtual_dom$VirtualDom$property, key, value),
			_List_Nil,
			'');
	});
var rtfeldman$elm_css$Html$Styled$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			elm$json$Json$Encode$string(string));
	});
var rtfeldman$elm_css$Html$Styled$Attributes$href = function (url) {
	return A2(rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'href', url);
};
var rtfeldman$elm_css$Html$Styled$Attributes$id = rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('id');
var rtfeldman$elm_css$Html$Styled$Attributes$src = function (url) {
	return A2(rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'src', url);
};
var rtfeldman$elm_css$Html$Styled$Attributes$target = rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('target');
var author$project$Main$contact = function (model) {
	var sizes = model.aB ? {
		_: rtfeldman$elm_css$Css$vw(15),
		af: rtfeldman$elm_css$Css$px(14)
	} : {
		_: rtfeldman$elm_css$Css$vw(8),
		af: rtfeldman$elm_css$Css$px(20)
	};
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$id('contact'),
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$displayFlex,
						rtfeldman$elm_css$Css$alignItems(rtfeldman$elm_css$Css$center),
						rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center),
						rtfeldman$elm_css$Css$flexDirection(rtfeldman$elm_css$Css$row),
						rtfeldman$elm_css$Css$height(
						rtfeldman$elm_css$Css$vh(10))
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes._)
							])),
						rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:beaa.csaka@gmail.com'),
						rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$img,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$src('./icons/mail_white.svg'),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$height(sizes.af)
									]))
							]),
						_List_Nil)
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes._)
							])),
						rtfeldman$elm_css$Html$Styled$Attributes$href('https://www.instagram.com/beaaacska'),
						rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$img,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$src('./icons/instagram_white.svg'),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$height(sizes.af)
									]))
							]),
						_List_Nil)
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes._)
							])),
						rtfeldman$elm_css$Html$Styled$Attributes$href('https://www.behance.net/beatacsaka'),
						rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$img,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$src('./icons/behance_white.svg'),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$height(sizes.af)
									]))
							]),
						_List_Nil)
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes._)
							])),
						rtfeldman$elm_css$Html$Styled$Attributes$href('https://www.pinterest.com/beaacsaka'),
						rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$img,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$src('./icons/pinterest_white.svg'),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$height(sizes.af)
									]))
							]),
						_List_Nil)
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes._)
							])),
						rtfeldman$elm_css$Html$Styled$Attributes$href('https://www.linkedin.com/in/csakabeata/'),
						rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$img,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$src('./icons/linkedin_white.svg'),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$height(sizes.af)
									]))
							]),
						_List_Nil)
					]))
			]));
};
var rtfeldman$elm_css$Css$backgroundColor = function (c) {
	return A2(rtfeldman$elm_css$Css$property, 'background-color', c.M);
};
var rtfeldman$elm_css$Css$color = function (c) {
	return A2(rtfeldman$elm_css$Css$property, 'color', c.M);
};
var rtfeldman$elm_css$Css$column = _Utils_update(
	rtfeldman$elm_css$Css$row,
	{M: 'column'});
var rtfeldman$elm_css$Css$fontSize = rtfeldman$elm_css$Css$prop1('font-size');
var rtfeldman$elm_css$Css$margin = rtfeldman$elm_css$Css$prop1('margin');
var rtfeldman$elm_css$Css$marginTop = rtfeldman$elm_css$Css$prop1('margin-top');
var rtfeldman$elm_css$Css$padding = rtfeldman$elm_css$Css$prop1('padding');
var rtfeldman$elm_css$Css$paddingTop = rtfeldman$elm_css$Css$prop1('padding-top');
var rtfeldman$elm_css$Css$VMinUnits = 0;
var rtfeldman$elm_css$Css$vmin = A2(rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'vmin');
var rtfeldman$elm_css$Html$Styled$nav = rtfeldman$elm_css$Html$Styled$node('nav');
var elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var rtfeldman$elm_css$VirtualDom$Styled$Unstyled = function (a) {
	return {$: 4, a: a};
};
var rtfeldman$elm_css$VirtualDom$Styled$text = function (str) {
	return rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
		elm$virtual_dom$VirtualDom$text(str));
};
var rtfeldman$elm_css$Html$Styled$text = rtfeldman$elm_css$VirtualDom$Styled$text;
var author$project$Main$footer = function (model) {
	return A2(
		rtfeldman$elm_css$Html$Styled$nav,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$padding(
						rtfeldman$elm_css$Css$vh(3.5)),
						rtfeldman$elm_css$Css$marginTop(
						rtfeldman$elm_css$Css$vh(5)),
						rtfeldman$elm_css$Css$fontSize(
						rtfeldman$elm_css$Css$px(12)),
						rtfeldman$elm_css$Css$backgroundColor(author$project$Color$black),
						rtfeldman$elm_css$Css$color(author$project$Color$white),
						rtfeldman$elm_css$Css$height(
						rtfeldman$elm_css$Css$vmin(25)),
						rtfeldman$elm_css$Css$displayFlex,
						rtfeldman$elm_css$Css$alignItems(rtfeldman$elm_css$Css$center),
						rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center),
						rtfeldman$elm_css$Css$flexDirection(rtfeldman$elm_css$Css$column)
					]))
			]),
		_List_fromArray(
			[
				author$project$Main$contact(model),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$paddingTop(
								rtfeldman$elm_css$Css$vh(2))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('© 2021 Beáta Csáka. All Rights Reserved')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./bea_logo_white.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$margin(
								rtfeldman$elm_css$Css$px(20)),
								rtfeldman$elm_css$Css$height(
								rtfeldman$elm_css$Css$vmin(6)),
								rtfeldman$elm_css$Css$width(
								rtfeldman$elm_css$Css$vmin(6))
							]))
					]),
				_List_Nil)
			]));
};
var author$project$Main$JumpTo = function (a) {
	return {$: 7, a: a};
};
var author$project$Main$OpenModal = function (a) {
	return {$: 2, a: a};
};
var rtfeldman$elm_css$Css$flexEnd = rtfeldman$elm_css$Css$prop1('flex-end');
var rtfeldman$elm_css$Css$Preprocess$ExtendSelector = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Structure$PseudoClassSelector = function (a) {
	return {$: 2, a: a};
};
var rtfeldman$elm_css$Css$pseudoClass = function (_class) {
	return rtfeldman$elm_css$Css$Preprocess$ExtendSelector(
		rtfeldman$elm_css$Css$Structure$PseudoClassSelector(_class));
};
var rtfeldman$elm_css$Css$hover = rtfeldman$elm_css$Css$pseudoClass('hover');
var rtfeldman$elm_css$Css$letterSpacing = rtfeldman$elm_css$Css$prop1('letter-spacing');
var rtfeldman$elm_css$Css$textDecorationLine = rtfeldman$elm_css$Css$prop1('text-decoration-line');
var rtfeldman$elm_css$Css$underline = {aH: 0, M: 'underline'};
var rtfeldman$elm_css$Css$visibility = rtfeldman$elm_css$Css$prop1('visibility');
var rtfeldman$elm_css$Css$visible = {aC: 0, H: 0, M: 'visible', a6: 0};
var rtfeldman$elm_css$Html$Styled$p = rtfeldman$elm_css$Html$Styled$node('p');
var elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var rtfeldman$elm_css$VirtualDom$Styled$on = F2(
	function (eventName, handler) {
		return A3(
			rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2(elm$virtual_dom$VirtualDom$on, eventName, handler),
			_List_Nil,
			'');
	});
var rtfeldman$elm_css$Html$Styled$Events$on = F2(
	function (event, decoder) {
		return A2(
			rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var rtfeldman$elm_css$Html$Styled$Events$onClick = function (msg) {
	return A2(
		rtfeldman$elm_css$Html$Styled$Events$on,
		'click',
		elm$json$Json$Decode$succeed(msg));
};
var author$project$Main$menu = function (model) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$fontSize(
						rtfeldman$elm_css$Css$px(12)),
						rtfeldman$elm_css$Css$letterSpacing(
						rtfeldman$elm_css$Css$px(5)),
						rtfeldman$elm_css$Css$displayFlex,
						rtfeldman$elm_css$Css$flexDirection(rtfeldman$elm_css$Css$column),
						rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center),
						rtfeldman$elm_css$Css$width(
						rtfeldman$elm_css$Css$vw(18)),
						rtfeldman$elm_css$Css$height(
						rtfeldman$elm_css$Css$vw(30)),
						model.C ? rtfeldman$elm_css$Css$visibility(rtfeldman$elm_css$Css$visible) : rtfeldman$elm_css$Css$visibility(rtfeldman$elm_css$Css$hidden)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Main$JumpTo('projects')),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$displayFlex,
								rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$flexEnd),
								rtfeldman$elm_css$Css$hover(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$textDecorationLine(rtfeldman$elm_css$Css$underline)
									]))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('PROJECTS/')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$href('about'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$color(author$project$Color$black),
								rtfeldman$elm_css$Css$textDecorationLine(rtfeldman$elm_css$Css$none)
							]))
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$p,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Events$onClick(
								author$project$Main$OpenModal(7)),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$displayFlex,
										rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$flexEnd),
										rtfeldman$elm_css$Css$hover(
										_List_fromArray(
											[
												rtfeldman$elm_css$Css$textDecorationLine(rtfeldman$elm_css$Css$underline)
											]))
									]))
							]),
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$text('ABOUT/')
							]))
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Main$JumpTo('contact')),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$displayFlex,
								rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$flexEnd),
								rtfeldman$elm_css$Css$hover(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$textDecorationLine(rtfeldman$elm_css$Css$underline)
									]))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('CONTACT/')
					]))
			]));
};
var author$project$Main$TogleMenu = {$: 0};
var rtfeldman$elm_css$Css$angleConverter = F2(
	function (suffix, angleVal) {
		return {
			cJ: 0,
			J: 0,
			M: _Utils_ap(
				elm$core$String$fromFloat(angleVal),
				suffix)
		};
	});
var rtfeldman$elm_css$Css$deg = rtfeldman$elm_css$Css$angleConverter('deg');
var rtfeldman$elm_css$Css$rotate = function (_n0) {
	var value = _n0.M;
	return {
		e: 0,
		M: A2(
			rtfeldman$elm_css$Css$cssFunction,
			'rotate',
			_List_fromArray(
				[value]))
	};
};
var rtfeldman$elm_css$Css$valuesOrNone = function (list) {
	return elm$core$List$isEmpty(list) ? {M: 'none'} : {
		M: A2(
			elm$core$String$join,
			' ',
			A2(
				elm$core$List$map,
				function ($) {
					return $.M;
				},
				list))
	};
};
var rtfeldman$elm_css$Css$transforms = A2(
	elm$core$Basics$composeL,
	rtfeldman$elm_css$Css$prop1('transform'),
	rtfeldman$elm_css$Css$valuesOrNone);
var rtfeldman$elm_css$Css$transform = function (only) {
	return rtfeldman$elm_css$Css$transforms(
		_List_fromArray(
			[only]));
};
var rtfeldman$elm_css$Css$UnitlessInteger = 0;
var rtfeldman$elm_css$Css$zero = {aV: 0, ay: 0, S: 0, az: 0, aA: 0, ad: 0, ae: 0, aX: 0, V: 0, bi: 0, aK: '', a5: 0, M: '0'};
var author$project$Main$menuButton = function (model) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$height(
						rtfeldman$elm_css$Css$vw(28)),
						rtfeldman$elm_css$Css$width(
						rtfeldman$elm_css$Css$vw(3)),
						rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
						rtfeldman$elm_css$Css$displayFlex,
						rtfeldman$elm_css$Css$flexDirection(rtfeldman$elm_css$Css$column)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								A2(rtfeldman$elm_css$Css$property, 'writing-mode', 'vertical-rl'),
								rtfeldman$elm_css$Css$transform(
								rtfeldman$elm_css$Css$rotate(
									rtfeldman$elm_css$Css$deg(180))),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$width(
								rtfeldman$elm_css$Css$vw(3)),
								rtfeldman$elm_css$Css$height(
								rtfeldman$elm_css$Css$vw(12)),
								rtfeldman$elm_css$Css$displayFlex,
								rtfeldman$elm_css$Css$alignItems(rtfeldman$elm_css$Css$center),
								rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$flexEnd),
								rtfeldman$elm_css$Css$letterSpacing(
								rtfeldman$elm_css$Css$px(5)),
								rtfeldman$elm_css$Css$fontSize(
								rtfeldman$elm_css$Css$px(12))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('BEÁTA CSÁKA')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Main$TogleMenu),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(
								rtfeldman$elm_css$Css$vw(3)),
								rtfeldman$elm_css$Css$paddingTop(
								rtfeldman$elm_css$Css$vw(1))
							]))
					]),
				_List_fromArray(
					[
						model.C ? A2(
						rtfeldman$elm_css$Html$Styled$img,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$src('./buttons/x_black.svg'),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$height(
										rtfeldman$elm_css$Css$vmin(3)),
										rtfeldman$elm_css$Css$width(
										rtfeldman$elm_css$Css$vmin(3)),
										rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
									]))
							]),
						_List_Nil) : A2(
						rtfeldman$elm_css$Html$Styled$img,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$src('./buttons/menu.svg'),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$height(
										rtfeldman$elm_css$Css$vmin(3)),
										rtfeldman$elm_css$Css$width(
										rtfeldman$elm_css$Css$vmin(3)),
										rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
									]))
							]),
						_List_Nil)
					]))
			]));
};
var rtfeldman$elm_css$Css$marginBottom = rtfeldman$elm_css$Css$prop1('margin-bottom');
var rtfeldman$elm_css$Css$marginLeft = rtfeldman$elm_css$Css$prop1('margin-left');
var rtfeldman$elm_css$Css$marginRight = rtfeldman$elm_css$Css$prop1('margin-right');
var rtfeldman$elm_css$Css$maxWidth = rtfeldman$elm_css$Css$prop1('max-width');
var rtfeldman$elm_css$Css$verticalAlign = function (fn) {
	return A3(
		rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'verticalAlign',
		'vertical-align',
		fn(rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var author$project$Main$homeDesktop = function (model) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$id('home'),
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$displayFlex,
						rtfeldman$elm_css$Css$alignItems(rtfeldman$elm_css$Css$center),
						rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center),
						rtfeldman$elm_css$Css$flexDirection(rtfeldman$elm_css$Css$row),
						rtfeldman$elm_css$Css$verticalAlign(rtfeldman$elm_css$Css$center)
					]))
			]),
		_List_fromArray(
			[
				author$project$Main$menuButton(model),
				author$project$Main$menu(model),
				A2(
				rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$href('about')
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$img,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$src('./buttons/logo.svg'),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$marginLeft(
										rtfeldman$elm_css$Css$vw(5)),
										rtfeldman$elm_css$Css$marginTop(
										rtfeldman$elm_css$Css$vh(15)),
										rtfeldman$elm_css$Css$marginRight(
										rtfeldman$elm_css$Css$vw(25)),
										rtfeldman$elm_css$Css$marginBottom(
										rtfeldman$elm_css$Css$vh(15)),
										rtfeldman$elm_css$Css$height(
										rtfeldman$elm_css$Css$vw(28)),
										rtfeldman$elm_css$Css$width(
										rtfeldman$elm_css$Css$vw(28)),
										rtfeldman$elm_css$Css$maxWidth(
										rtfeldman$elm_css$Css$vw(100))
									])),
								rtfeldman$elm_css$Html$Styled$Events$onClick(
								author$project$Main$OpenModal(7))
							]),
						_List_Nil)
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./buttons/down.svg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$height(
								rtfeldman$elm_css$Css$vmin(3)),
								rtfeldman$elm_css$Css$width(
								rtfeldman$elm_css$Css$vmin(3))
							])),
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Main$JumpTo('projects'))
					]),
				_List_Nil)
			]));
};
var author$project$Main$menuButtonMobile = function (model) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$height(
						rtfeldman$elm_css$Css$vw(34)),
						rtfeldman$elm_css$Css$width(
						rtfeldman$elm_css$Css$vw(28)),
						rtfeldman$elm_css$Css$displayFlex,
						rtfeldman$elm_css$Css$alignItems(rtfeldman$elm_css$Css$center),
						rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center)
					]))
			]),
		_List_fromArray(
			[
				model.C ? A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./buttons/x_black.svg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$height(
								rtfeldman$elm_css$Css$px(16)),
								rtfeldman$elm_css$Css$width(
								rtfeldman$elm_css$Css$px(16)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$padding(
								rtfeldman$elm_css$Css$vw(5))
							])),
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Main$TogleMenu)
					]),
				_List_Nil) : A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./buttons/menu.svg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$height(
								rtfeldman$elm_css$Css$px(16)),
								rtfeldman$elm_css$Css$width(
								rtfeldman$elm_css$Css$px(16)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$padding(
								rtfeldman$elm_css$Css$vw(5))
							])),
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Main$TogleMenu)
					]),
				_List_Nil)
			]));
};
var author$project$Main$OpenAbout = {$: 3};
var rtfeldman$elm_css$Css$fixed = {aN: 0, c8: 0, bp: 0, M: 'fixed'};
var rtfeldman$elm_css$Css$int = function (val) {
	return {
		R: 0,
		bc: 0,
		ae: 0,
		K: 0,
		aX: 0,
		bf: 0,
		V: val,
		aK: '',
		a5: 0,
		M: elm$core$String$fromInt(val)
	};
};
var rtfeldman$elm_css$Css$position = rtfeldman$elm_css$Css$prop1('position');
var rtfeldman$elm_css$Css$scroll = {aN: 0, bV: 0, cc: 0, aC: 0, db: 0, M: 'scroll'};
var rtfeldman$elm_css$Css$top = rtfeldman$elm_css$Css$prop1('top');
var rtfeldman$elm_css$Css$zIndex = rtfeldman$elm_css$Css$prop1('z-index');
var author$project$Main$menuCss = rtfeldman$elm_css$Html$Styled$Attributes$css(
	_List_fromArray(
		[
			rtfeldman$elm_css$Css$backgroundColor(author$project$Color$white),
			rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$fixed),
			rtfeldman$elm_css$Css$width(
			rtfeldman$elm_css$Css$vw(100)),
			rtfeldman$elm_css$Css$top(rtfeldman$elm_css$Css$zero),
			rtfeldman$elm_css$Css$height(
			rtfeldman$elm_css$Css$vh(100)),
			rtfeldman$elm_css$Css$overflow(rtfeldman$elm_css$Css$scroll),
			rtfeldman$elm_css$Css$zIndex(
			rtfeldman$elm_css$Css$int(2))
		]));
var author$project$Main$menuMobile = function (model) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[author$project$Main$menuCss]),
		_List_fromArray(
			[
				author$project$Main$menuButtonMobile(model),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$displayFlex,
								rtfeldman$elm_css$Css$alignItems(rtfeldman$elm_css$Css$center),
								rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center),
								rtfeldman$elm_css$Css$flexDirection(rtfeldman$elm_css$Css$column),
								rtfeldman$elm_css$Css$verticalAlign(rtfeldman$elm_css$Css$center)
							]))
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$p,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Events$onClick(
								author$project$Main$JumpTo('projects')),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$displayFlex,
										rtfeldman$elm_css$Css$paddingTop(
										rtfeldman$elm_css$Css$vh(20)),
										rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center)
									]))
							]),
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$text('PROJECTS/')
							])),
						A2(
						rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$href('about'),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$color(author$project$Color$black),
										rtfeldman$elm_css$Css$textDecorationLine(rtfeldman$elm_css$Css$none)
									]))
							]),
						_List_fromArray(
							[
								A2(
								rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[
										rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Main$OpenAbout),
										rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												rtfeldman$elm_css$Css$displayFlex,
												rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center)
											]))
									]),
								_List_fromArray(
									[
										rtfeldman$elm_css$Html$Styled$text('ABOUT/')
									]))
							])),
						A2(
						rtfeldman$elm_css$Html$Styled$p,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Events$onClick(
								author$project$Main$JumpTo('contact')),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$displayFlex,
										rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center)
									]))
							]),
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$text('CONTACT/')
							]))
					]))
			]));
};
var author$project$Main$homeMobile = function (model) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$id('home'),
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$displayFlex,
						rtfeldman$elm_css$Css$alignItems(rtfeldman$elm_css$Css$center),
						rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center),
						rtfeldman$elm_css$Css$flexDirection(rtfeldman$elm_css$Css$row),
						rtfeldman$elm_css$Css$verticalAlign(rtfeldman$elm_css$Css$center)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								A2(rtfeldman$elm_css$Css$property, 'writing-mode', 'vertical-rl'),
								rtfeldman$elm_css$Css$transform(
								rtfeldman$elm_css$Css$rotate(
									rtfeldman$elm_css$Css$deg(180))),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$height(
								rtfeldman$elm_css$Css$vw(34)),
								rtfeldman$elm_css$Css$width(
								rtfeldman$elm_css$Css$vw(28)),
								rtfeldman$elm_css$Css$displayFlex,
								rtfeldman$elm_css$Css$alignItems(rtfeldman$elm_css$Css$center),
								rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center),
								rtfeldman$elm_css$Css$letterSpacing(
								rtfeldman$elm_css$Css$px(5)),
								rtfeldman$elm_css$Css$fontSize(
								rtfeldman$elm_css$Css$px(8))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('BEÁTA CSÁKA')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$href('about')
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$img,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$src('./buttons/logo.svg'),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$marginLeft(
										rtfeldman$elm_css$Css$vw(5)),
										rtfeldman$elm_css$Css$marginTop(
										rtfeldman$elm_css$Css$vh(10)),
										rtfeldman$elm_css$Css$marginRight(
										rtfeldman$elm_css$Css$vw(5)),
										rtfeldman$elm_css$Css$marginBottom(
										rtfeldman$elm_css$Css$vh(10)),
										rtfeldman$elm_css$Css$height(
										rtfeldman$elm_css$Css$vw(34)),
										rtfeldman$elm_css$Css$width(
										rtfeldman$elm_css$Css$vw(34)),
										rtfeldman$elm_css$Css$maxWidth(
										rtfeldman$elm_css$Css$vw(100))
									])),
								rtfeldman$elm_css$Html$Styled$Events$onClick(
								author$project$Main$OpenModal(7))
							]),
						_List_Nil)
					])),
				author$project$Main$menuButtonMobile(model),
				model.C ? author$project$Main$menuMobile(model) : A2(rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)
			]));
};
var author$project$Main$home = function (model) {
	return model.aB ? author$project$Main$homeMobile(model) : author$project$Main$homeDesktop(model);
};
var author$project$Main$CloseModal = {$: 1};
var rtfeldman$elm_css$Css$auto = {cI: 0, a: 0, at: 0, bc: 0, c$: 0, ay: 0, S: 0, K: 0, aC: 0, H: 0, bp: 0, aJ: 0, A: 0, M: 'auto'};
var author$project$Projects$aboutCss = rtfeldman$elm_css$Html$Styled$Attributes$css(
	_List_fromArray(
		[
			rtfeldman$elm_css$Css$backgroundColor(author$project$Color$black),
			rtfeldman$elm_css$Css$color(author$project$Color$white),
			rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$fixed),
			rtfeldman$elm_css$Css$width(
			rtfeldman$elm_css$Css$vw(100)),
			rtfeldman$elm_css$Css$top(rtfeldman$elm_css$Css$zero),
			rtfeldman$elm_css$Css$height(
			rtfeldman$elm_css$Css$vh(100)),
			rtfeldman$elm_css$Css$overflow(rtfeldman$elm_css$Css$auto),
			rtfeldman$elm_css$Css$overflowY(rtfeldman$elm_css$Css$auto),
			rtfeldman$elm_css$Css$pointerEvents(rtfeldman$elm_css$Css$auto),
			rtfeldman$elm_css$Css$displayFlex,
			rtfeldman$elm_css$Css$flexDirection(rtfeldman$elm_css$Css$row),
			rtfeldman$elm_css$Css$fontSize(
			rtfeldman$elm_css$Css$px(14))
		]));
var author$project$Projects$aboutCssMobile = rtfeldman$elm_css$Html$Styled$Attributes$css(
	_List_fromArray(
		[
			rtfeldman$elm_css$Css$backgroundColor(author$project$Color$black),
			rtfeldman$elm_css$Css$color(author$project$Color$white),
			rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$fixed),
			rtfeldman$elm_css$Css$width(
			rtfeldman$elm_css$Css$vw(100)),
			rtfeldman$elm_css$Css$top(rtfeldman$elm_css$Css$zero),
			rtfeldman$elm_css$Css$height(
			rtfeldman$elm_css$Css$vh(100)),
			rtfeldman$elm_css$Css$overflow(rtfeldman$elm_css$Css$auto),
			rtfeldman$elm_css$Css$overflowY(rtfeldman$elm_css$Css$auto),
			rtfeldman$elm_css$Css$pointerEvents(rtfeldman$elm_css$Css$auto),
			rtfeldman$elm_css$Css$fontSize(
			rtfeldman$elm_css$Css$px(12))
		]));
var rtfeldman$elm_css$Css$float = function (fn) {
	return A3(
		rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'float',
		'float',
		fn(rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var rtfeldman$elm_css$Css$left = rtfeldman$elm_css$Css$prop1('left');
var rtfeldman$elm_css$Css$paddingBottom = rtfeldman$elm_css$Css$prop1('padding-bottom');
var rtfeldman$elm_css$Css$right = rtfeldman$elm_css$Css$prop1('right');
var rtfeldman$elm_css$Css$textAlign = function (fn) {
	return A3(
		rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'textAlign',
		'text-align',
		fn(rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var author$project$Projects$about = F2(
	function (onMobile, messages) {
		var sizes = onMobile ? {
			aa: rtfeldman$elm_css$Css$vw(15),
			bv: rtfeldman$elm_css$Css$vw(80),
			bw: author$project$Projects$aboutCssMobile,
			g: rtfeldman$elm_css$Css$vw(10),
			af: rtfeldman$elm_css$Css$vw(8),
			bH: rtfeldman$elm_css$Css$vw(80),
			k: rtfeldman$elm_css$Css$vw(80),
			bN: rtfeldman$elm_css$Css$vh(0)
		} : {
			aa: rtfeldman$elm_css$Css$vw(5),
			bv: rtfeldman$elm_css$Css$vw(40),
			bw: author$project$Projects$aboutCss,
			g: rtfeldman$elm_css$Css$vw(8),
			af: rtfeldman$elm_css$Css$vw(5),
			bH: rtfeldman$elm_css$Css$vw(27),
			k: rtfeldman$elm_css$Css$vw(30),
			bN: rtfeldman$elm_css$Css$vh(15)
		};
		return A2(
			rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[sizes.bw]),
			_List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$width(sizes.aa),
									rtfeldman$elm_css$Css$height(sizes.aa),
									rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$fixed),
									rtfeldman$elm_css$Css$displayFlex,
									rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center)
								])),
							rtfeldman$elm_css$Html$Styled$Events$onClick(messages.cN)
						]),
					_List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Html$Styled$img,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$src('./buttons/x_white.svg'),
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$height(
											rtfeldman$elm_css$Css$px(12)),
											rtfeldman$elm_css$Css$width(
											rtfeldman$elm_css$Css$px(12))
										])),
									rtfeldman$elm_css$Html$Styled$Events$onClick(messages.cN)
								]),
							_List_Nil)
						])),
					A2(
					rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$width(sizes.bv),
									rtfeldman$elm_css$Css$marginLeft(sizes.g),
									rtfeldman$elm_css$Css$paddingTop(
									rtfeldman$elm_css$Css$vh(5)),
									rtfeldman$elm_css$Css$displayFlex,
									rtfeldman$elm_css$Css$flexDirection(rtfeldman$elm_css$Css$column),
									rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$left)
								]))
						]),
					_List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									rtfeldman$elm_css$Html$Styled$img,
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$Attributes$src('./about/csb.svg'),
											rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													rtfeldman$elm_css$Css$height(sizes.af),
													rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
												]))
										]),
									_List_Nil)
								])),
							A2(
							rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									rtfeldman$elm_css$Html$Styled$img,
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$Attributes$src('./about/csb_signed.png'),
											rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													rtfeldman$elm_css$Css$width(sizes.bH),
													rtfeldman$elm_css$Css$maxWidth(
													rtfeldman$elm_css$Css$vw(80)),
													rtfeldman$elm_css$Css$float(rtfeldman$elm_css$Css$right)
												]))
										]),
									_List_Nil)
								]))
						])),
					A2(
					rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$width(sizes.k),
									rtfeldman$elm_css$Css$marginLeft(sizes.g),
									rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$left),
									rtfeldman$elm_css$Css$paddingTop(sizes.bN)
								]))
						]),
					_List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$text('Hello.')
								])),
							A2(
							rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$text('My name is Beáta Csáka.')
								])),
							A2(
							rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$text('I am a multi-disciplinary designer, specialized in product, graphic and interior design.  I studied design at the University of Art and Design of Cluj-Napoca and Accademia di Belli Arti di Bari, Italy, obtaining my BA and MA degrees.')
								])),
							A2(
							rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$text('I am always seeking for beauty and searching to find equilibrium in everything I do, whenever it is about materials, color palettes or proportions. My work distinguishes itself with the combination of mostly natural, bold, high quality materials and color schemes, while my love for minimalism is peppered with the combination of all kind of styles, depending on the project.')
								])),
							A2(
							rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$text('Through the vast number of collaborations from various fields and diverse range of clients, I explore function, through the perspective of aesthetics.')
								])),
							A2(
							rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$text('I believes design is more than producing something, it is a journey, that requires some qualities along the way, that I consider I do have. First, curiosity, to question everything, to understand why things are the way they are. Then, courage to change them. Creativity to explore new concepts, forms and ideas. Discipline, to drive continual refinement. And the most important, passion, to be dedicated and enjoy the whole journey and deliver successful narratives through the visualization of my design work.')
								])),
							A2(
							rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$text('This online portfolio is a visual journey trough some of my projects!')
								])),
							A2(
							rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$paddingBottom(
											rtfeldman$elm_css$Css$px(105))
										]))
								]),
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$text('Enjoy it!')
								]))
						]))
				]));
	});
var author$project$Projects$astroCards = function (sizes) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$width(sizes.bq),
						rtfeldman$elm_css$Css$backgroundColor(author$project$Color$black),
						rtfeldman$elm_css$Css$marginLeft(sizes.g)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./astro/astro1.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./astro/astro2.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./astro/astro3.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./astro/astro4.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./astro/astro8.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./astro/astro9.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./astro/astro10.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./astro/astro11.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginBottom(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil)
			]));
};
var author$project$Color$boschBlue = A3(rtfeldman$elm_css$Css$rgb, 26, 35, 251);
var rtfeldman$elm_css$Css$EmUnits = 0;
var rtfeldman$elm_css$Css$em = A2(rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'em');
var rtfeldman$elm_css$Css$lineHeight = rtfeldman$elm_css$Css$prop1('line-height');
var author$project$Projects$bosch = function (sizes) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$width(sizes.bq),
						rtfeldman$elm_css$Css$backgroundColor(author$project$Color$boschBlue),
						rtfeldman$elm_css$Css$marginLeft(sizes.g)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./bosch/bosch1.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./bosch/bosch2.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.k),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$color(author$project$Color$white),
								rtfeldman$elm_css$Css$fontSize(sizes.o),
								rtfeldman$elm_css$Css$marginLeft(
								rtfeldman$elm_css$Css$vw(6)),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$marginBottom(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$lineHeight(
								rtfeldman$elm_css$Css$em(2))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('Wall art collaboration project made for BOSCH Romania. I created 3 large mural illustrations for the IT office of BOSCH in Cluj-Napoca.  The main illustration represents a gigantic computer screen in a paralel fantasy universe, where the principal character (computer scientist), hunts down the bug monsters that are eating the code. Each and every detail refers to computer science (for example the sun is a wiFi planet, the trees have mother board branches and the clouds are storage spaces, collecting information from the imaginary IT universe). I wanted to create an atmosphere that captures the dynamism of the company ecosystem with some dedicated illustrations and a vivid color palette, showing that the IT life is not that boring as it seems.')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./bosch/bosch4.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./bosch/bosch5.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.k),
								rtfeldman$elm_css$Css$color(author$project$Color$white),
								rtfeldman$elm_css$Css$fontSize(sizes.o),
								rtfeldman$elm_css$Css$marginLeft(
								rtfeldman$elm_css$Css$vw(6)),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$marginBottom(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$lineHeight(
								rtfeldman$elm_css$Css$em(2))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('The second wall illustration shows the importance of professionals and teamwork, which is the base of the company’s core values. Together, they build a world of IOT, where everything is connected.')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./bosch/bosch7.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.k),
								rtfeldman$elm_css$Css$color(author$project$Color$white),
								rtfeldman$elm_css$Css$fontSize(sizes.o),
								rtfeldman$elm_css$Css$marginLeft(
								rtfeldman$elm_css$Css$vw(6)),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$marginBottom(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$lineHeight(
								rtfeldman$elm_css$Css$em(2))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('The third illustration is a reinterpretation of Michelangelo’s  famous fresco painting, The Creation of Adam. It illustrates the creation of the machines, and the human is shown as God, the creator.')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./bosch/bosch9.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./bosch/bosch10.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./bosch/bosch11.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./bosch/bosch12.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./bosch/bosch13.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginBottom(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil)
			]));
};
var author$project$Color$iceBlue = A3(rtfeldman$elm_css$Css$rgb, 159, 178, 220);
var author$project$Projects$crown = function (sizes) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$width(sizes.bq),
						rtfeldman$elm_css$Css$backgroundColor(author$project$Color$white),
						rtfeldman$elm_css$Css$marginLeft(sizes.g)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./crown/crown1.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./crown/crown2.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(22))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./crown/crown3.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(22))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$backgroundColor(author$project$Color$iceBlue),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(22)),
								rtfeldman$elm_css$Css$marginBottom(
								rtfeldman$elm_css$Css$px(0))
							]))
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$p,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$width(sizes.k),
										rtfeldman$elm_css$Css$color(author$project$Color$white),
										rtfeldman$elm_css$Css$fontSize(sizes.o),
										rtfeldman$elm_css$Css$marginLeft(
										rtfeldman$elm_css$Css$vw(6)),
										rtfeldman$elm_css$Css$paddingTop(
										rtfeldman$elm_css$Css$px(40)),
										rtfeldman$elm_css$Css$paddingBottom(
										rtfeldman$elm_css$Css$px(40)),
										rtfeldman$elm_css$Css$marginBottom(rtfeldman$elm_css$Css$zero),
										rtfeldman$elm_css$Css$lineHeight(
										rtfeldman$elm_css$Css$em(2))
									]))
							]),
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$text('Inspired by a frosty winter morning, the Ice and wire headpiece tells the story of the Snow Queen. It combines aggressive, sharp materials, like glass and wire, hinted with glittery dust, providing a frozen icicle feel. This handcrafted statement headpiece was created as a university project.')
							]))
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./crown/crown5.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(22))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./crown/crown6.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(22))
							]))
					]),
				_List_Nil)
			]));
};
var author$project$Color$dochiaPurple = A3(rtfeldman$elm_css$Css$rgb, 96, 96, 166);
var author$project$Projects$dochia = function (sizes) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$width(sizes.bq),
						rtfeldman$elm_css$Css$backgroundColor(author$project$Color$dochiaPurple),
						rtfeldman$elm_css$Css$marginLeft(sizes.g)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./dochia/dochia1.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./dochia/dochia2.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./dochia/dochia3.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./dochia/dochia4.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.k),
								rtfeldman$elm_css$Css$color(author$project$Color$white),
								rtfeldman$elm_css$Css$fontSize(sizes.o),
								rtfeldman$elm_css$Css$marginLeft(
								rtfeldman$elm_css$Css$vw(6)),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$marginBottom(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$lineHeight(
								rtfeldman$elm_css$Css$em(2))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('Casa lu’ Dochia is a bed and breakfast located in Breb, a small village in Transylvania, Romania. It is characterized by rustic touches and traditional elements and its rural atmosphere. It gained its name from an old lady, called Dochia, who owned the house, where this small business is, before she died.')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./dochia/dochia7.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./dochia/dochia8.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil)
			]));
};
var author$project$Projects$ec = function (sizes) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$width(sizes.bq),
						rtfeldman$elm_css$Css$backgroundColor(author$project$Color$white),
						rtfeldman$elm_css$Css$marginLeft(sizes.g)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./ec/7sins1.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./ec/7sins2.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./ec/7sins3.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./ec/7sins4.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./ec/7sins5.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./ec/7sins6.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil)
			]));
};
var author$project$Color$inkBlue = A3(rtfeldman$elm_css$Css$rgb, 111, 122, 210);
var author$project$Color$librisBlue = A3(rtfeldman$elm_css$Css$rgb, 62, 64, 149);
var author$project$Projects$exlibris = function (sizes) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$width(sizes.bq),
						rtfeldman$elm_css$Css$backgroundColor(author$project$Color$librisBlue),
						rtfeldman$elm_css$Css$marginLeft(sizes.g)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./exlibris/exlibris1.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./exlibris/exlibris2.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./exlibris/exlibris3.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./exlibris/exlibris4.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./exlibris/exlibris5.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./exlibris/exlibris6.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.k),
								rtfeldman$elm_css$Css$color(author$project$Color$inkBlue),
								rtfeldman$elm_css$Css$fontSize(sizes.o),
								rtfeldman$elm_css$Css$marginLeft(
								rtfeldman$elm_css$Css$vw(6)),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$marginBottom(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$lineHeight(
								rtfeldman$elm_css$Css$em(2))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('An ex libris is a bookplate, than is usually a small print or decorative label pasted or stamped into a book, often on the front endpaper, to indicate its owner, with an unique design. An ex libris usually consist of the owner\'s name or monogram, and a motif that relates to the owner of the book. This project represents a very personal one, because it was made for my father, who loves and collects books in his home library.')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./exlibris/exlibris8.gif'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./exlibris/exlibris9.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil)
			]));
};
var author$project$Color$heronBlack = A3(rtfeldman$elm_css$Css$rgb, 35, 30, 37);
var author$project$Color$paleYellow = A3(rtfeldman$elm_css$Css$rgb, 255, 251, 214);
var author$project$Projects$heron = function (sizes) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$width(sizes.bq),
						rtfeldman$elm_css$Css$backgroundColor(author$project$Color$heronBlack),
						rtfeldman$elm_css$Css$marginLeft(sizes.g)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./heron/1.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$pointerEvents(rtfeldman$elm_css$Css$none)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./heron/2.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.k),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$fontSize(sizes.o),
								rtfeldman$elm_css$Css$color(author$project$Color$paleYellow),
								rtfeldman$elm_css$Css$marginLeft(
								rtfeldman$elm_css$Css$vw(6)),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$marginBottom(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$lineHeight(
								rtfeldman$elm_css$Css$em(2))
							]))
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$p,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$css(_List_Nil)
							]),
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$text('The name of the collection is HERON (a representative element for the Art Deco style and movement), including niche objects, gaining its main inspiration from Art Deco.')
							])),
						A2(
						rtfeldman$elm_css$Html$Styled$p,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$css(_List_Nil)
							]),
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$text('The collection brings back its items from obsolescence, and gives them a design purpose, but a functional one aswell.  The collection consists of 4 objects: a chaise lounge, a bar cart, a set of auxiliary tables and a high stand for plants. The approached shapes recreate the Art Deco style through linear geometry, but breaks the symmetry, characteristic for it, while preserving its well-known elegance. High-quality materials have been used, combining Art Deco with contemporary notes, the collection\'s pieces getting a modern and stylish feel.')
							]))
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./heron/4.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./heron/5.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$marginBottom(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./heron/6.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$marginBottom(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./heron/7.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$marginBottom(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./heron/8.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil)
			]));
};
var author$project$Color$heronGold = A3(rtfeldman$elm_css$Css$rgb, 169, 143, 113);
var rtfeldman$elm_css$Css$RemUnits = 0;
var rtfeldman$elm_css$Css$rem = A2(rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'rem');
var author$project$Projects$heronLogo = function (sizes) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$width(sizes.bq),
						rtfeldman$elm_css$Css$backgroundColor(author$project$Color$white),
						rtfeldman$elm_css$Css$marginLeft(sizes.g)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./csb/csb_heron01.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./csb/csb_heron02.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./csb/csb_heron03.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$fontSize(sizes.o),
								rtfeldman$elm_css$Css$width(sizes.k),
								rtfeldman$elm_css$Css$lineHeight(
								rtfeldman$elm_css$Css$rem(1.5)),
								rtfeldman$elm_css$Css$marginLeft(
								rtfeldman$elm_css$Css$vw(6)),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$vh(8)),
								rtfeldman$elm_css$Css$marginBottom(
								rtfeldman$elm_css$Css$vh(8)),
								rtfeldman$elm_css$Css$color(author$project$Color$heronGold)
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('A visual identity for the luxury furniture collection, called Heron. It is a minimalistic and elegant representation of the majestic heron bird, constructed of golden thin lines. It is visually inspired by the Art Deco graphical style, just as the collection that it represents.')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./csb/csb_heron05.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./csb/csb_heron06.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./csb/csb_heron07.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil)
			]));
};
var author$project$Color$vividRed = A3(rtfeldman$elm_css$Css$rgb, 224, 67, 101);
var author$project$Projects$indagra = function (sizes) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$width(sizes.bq),
						rtfeldman$elm_css$Css$backgroundColor(author$project$Color$vividRed),
						rtfeldman$elm_css$Css$marginLeft(sizes.g)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./indagra/indagra1.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./indagra/indagra2.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./indagra/indagra4.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./indagra/indagra5.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.k),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$fontSize(sizes.o),
								rtfeldman$elm_css$Css$color(author$project$Color$white),
								rtfeldman$elm_css$Css$marginLeft(
								rtfeldman$elm_css$Css$vw(6)),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$marginBottom(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$lineHeight(
								rtfeldman$elm_css$Css$em(2))
							]))
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$p,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$css(_List_Nil)
							]),
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$text('New identity for INDAGRA SRL, a company specialized in passive fire protection. For their brand, Indagra wanted an identity that was minimal, professional and bold.   The aim was to create something bespoke as a reflection of the service level that they provide to their clients, being one of the best companies from their field of activity in Romania.  The symbol is a representation of a flame, with flat design style and modern gradients. The symbol is also a rethinked “i” letter, from Indagra.')
							]))
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./indagra/indagra7.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginBottom(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil)
			]));
};
var author$project$Color$kupsGrey = A3(rtfeldman$elm_css$Css$rgb, 55, 58, 65);
var author$project$Color$kupsWhite = A3(rtfeldman$elm_css$Css$rgb, 250, 250, 250);
var author$project$Projects$kups = function (sizes) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$width(sizes.bq),
						rtfeldman$elm_css$Css$backgroundColor(author$project$Color$kupsWhite),
						rtfeldman$elm_css$Css$marginLeft(sizes.g)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./kups/kups1.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$fontSize(sizes.o),
								rtfeldman$elm_css$Css$width(sizes.k),
								rtfeldman$elm_css$Css$lineHeight(
								rtfeldman$elm_css$Css$rem(1.5)),
								rtfeldman$elm_css$Css$marginLeft(
								rtfeldman$elm_css$Css$vw(6)),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$vh(8)),
								rtfeldman$elm_css$Css$marginBottom(
								rtfeldman$elm_css$Css$vh(8)),
								rtfeldman$elm_css$Css$color(author$project$Color$kupsGrey)
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('Illustration created for a small Coffee Shop in the heart of Cluj-Napoca. Their philosophy is that ‘Koffie unites people’s soul’. Based on this concept, I created an illustration for their shop window, which tells the story of coffee. Starting from the origins, it shows the process of how coffee is made, how it arrives in our favourite coffee shop, and how the delicious coffee is prepared by our favourite barista. Each detail is related to the concept of coffee’s journey. Grab a ‘kup’, follow the storyline!')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./kups/kups3.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./kups/kups4.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./kups/kups5.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./kups/kups6.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./kups/kups7.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./kups/kups8.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./kups/kups9.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./kups/kups10.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./kups/kups11.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginBottom(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil)
			]));
};
var rtfeldman$elm_css$Css$rgba = F4(
	function (r, g, b, alpha) {
		return {
			s: alpha,
			t: b,
			l: 0,
			x: g,
			z: r,
			M: A2(
				rtfeldman$elm_css$Css$cssFunction,
				'rgba',
				_Utils_ap(
					A2(
						elm$core$List$map,
						elm$core$String$fromInt,
						_List_fromArray(
							[r, g, b])),
					_List_fromArray(
						[
							elm$core$String$fromFloat(alpha)
						])))
		};
	});
var author$project$Color$transparent = A4(rtfeldman$elm_css$Css$rgba, 30, 30, 30, 0.8);
var rtfeldman$elm_css$Css$overflowX = rtfeldman$elm_css$Css$prop1('overflow-x');
var author$project$Projects$modalCss = rtfeldman$elm_css$Html$Styled$Attributes$css(
	_List_fromArray(
		[
			rtfeldman$elm_css$Css$backgroundColor(author$project$Color$transparent),
			rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$fixed),
			rtfeldman$elm_css$Css$width(
			rtfeldman$elm_css$Css$vw(100)),
			rtfeldman$elm_css$Css$top(rtfeldman$elm_css$Css$zero),
			rtfeldman$elm_css$Css$height(
			rtfeldman$elm_css$Css$vh(100)),
			rtfeldman$elm_css$Css$overflow(rtfeldman$elm_css$Css$auto),
			rtfeldman$elm_css$Css$overflowY(rtfeldman$elm_css$Css$auto),
			rtfeldman$elm_css$Css$overflowX(rtfeldman$elm_css$Css$hidden),
			rtfeldman$elm_css$Css$pointerEvents(rtfeldman$elm_css$Css$auto),
			rtfeldman$elm_css$Css$fontSize(
			rtfeldman$elm_css$Css$px(0)),
			rtfeldman$elm_css$Css$lineHeight(
			rtfeldman$elm_css$Css$px(0))
		]));
var rtfeldman$elm_css$Css$display = rtfeldman$elm_css$Css$prop1('display');
var rtfeldman$elm_css$Css$inlineBlock = {f: 0, M: 'inline-block'};
var author$project$Projects$modalFrame = F3(
	function (onMobile, messages, project) {
		var sizes = onMobile ? {
			bt: rtfeldman$elm_css$Css$px(140),
			aa: rtfeldman$elm_css$Css$vw(15),
			bu: rtfeldman$elm_css$Css$vh(15)
		} : {
			bt: rtfeldman$elm_css$Css$px(20),
			aa: rtfeldman$elm_css$Css$vw(10),
			bu: rtfeldman$elm_css$Css$vh(100)
		};
		return A2(
			rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[author$project$Projects$modalCss]),
			_List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$width(sizes.aa),
									rtfeldman$elm_css$Css$height(sizes.bu),
									rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$fixed),
									rtfeldman$elm_css$Css$displayFlex,
									rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center)
								])),
							rtfeldman$elm_css$Html$Styled$Events$onClick(messages.cN)
						]),
					_List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Html$Styled$img,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$src('./buttons/x_white.svg'),
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$height(
											rtfeldman$elm_css$Css$px(16)),
											rtfeldman$elm_css$Css$width(
											rtfeldman$elm_css$Css$px(16))
										])),
									rtfeldman$elm_css$Html$Styled$Events$onClick(messages.cN)
								]),
							_List_Nil)
						])),
					project,
					A2(
					rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$paddingTop(
									rtfeldman$elm_css$Css$vh(2)),
									rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$inlineBlock),
									rtfeldman$elm_css$Css$color(author$project$Color$white),
									rtfeldman$elm_css$Css$fontSize(
									rtfeldman$elm_css$Css$px(12)),
									rtfeldman$elm_css$Css$paddingBottom(sizes.bt)
								]))
						]),
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$text('© 2021 Beáta Csáka. All Rights Reserved')
						]))
				]));
	});
var author$project$Projects$plasmo = function (sizes) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$width(sizes.bq),
						rtfeldman$elm_css$Css$backgroundColor(author$project$Color$black),
						rtfeldman$elm_css$Css$marginLeft(sizes.g)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./plasmo/plasmo1.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./plasmo/plasmo2.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./plasmo/plasmo3.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./plasmo/plasmo4.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.k),
								rtfeldman$elm_css$Css$color(author$project$Color$white),
								rtfeldman$elm_css$Css$fontSize(sizes.o),
								rtfeldman$elm_css$Css$marginLeft(
								rtfeldman$elm_css$Css$vw(6)),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$lineHeight(
								rtfeldman$elm_css$Css$em(2))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('Plasmo Life is a company that is specialized in Plasma Lifting, which is a non-invasive aesthetic answer to surgical lifting procedures, it activates skin cells by application of own plasma. Plasma biomaterial contains unique combination of highly effective substances – growth factors, thrombocytes, leukocyte and stem cells. They activate new cell production and regenerate damaged cells.')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.k),
								rtfeldman$elm_css$Css$color(author$project$Color$white),
								rtfeldman$elm_css$Css$fontSize(sizes.o),
								rtfeldman$elm_css$Css$marginLeft(
								rtfeldman$elm_css$Css$vw(6)),
								rtfeldman$elm_css$Css$marginBottom(
								rtfeldman$elm_css$Css$vw(4)),
								rtfeldman$elm_css$Css$lineHeight(
								rtfeldman$elm_css$Css$em(2))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('The goal was to create a visual identity system that is inspired by the blood cells and plasma, yet is not scary and inspires trust and professionalism.')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./plasmo/plasmo6.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./plasmo/plasmo7.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./plasmo/plasmo8.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./plasmo/plasmo9.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./plasmo/plasmo10.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(-2))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./plasmo/plasmo11.png'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginBottom(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil)
			]));
};
var author$project$Color$vinoBlack = A3(rtfeldman$elm_css$Css$rgb, 12, 28, 44);
var author$project$Color$vinoBlue = A3(rtfeldman$elm_css$Css$rgb, 174, 202, 212);
var author$project$Projects$vino = function (sizes) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$width(sizes.bq),
						rtfeldman$elm_css$Css$backgroundColor(author$project$Color$white),
						rtfeldman$elm_css$Css$marginLeft(sizes.g)
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./vino/in_vino_veritas_01.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero)
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./vino/in_vino_veritas_02.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$backgroundColor(author$project$Color$vinoBlack),
								rtfeldman$elm_css$Css$displayFlex,
								rtfeldman$elm_css$Css$flexDirection(rtfeldman$elm_css$Css$row)
							]))
					]),
				_List_fromArray(
					[
						sizes.bg ? A2(rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil) : A2(
						rtfeldman$elm_css$Html$Styled$img,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$src('./vino/in_vino_veritas_33.jpg'),
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$width(
										rtfeldman$elm_css$Css$vw(30)),
										rtfeldman$elm_css$Css$maxWidth(
										rtfeldman$elm_css$Css$vw(30)),
										rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
										rtfeldman$elm_css$Css$marginTop(
										rtfeldman$elm_css$Css$px(7))
									]))
							]),
						_List_Nil),
						A2(
						rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$color(author$project$Color$vinoBlue),
										rtfeldman$elm_css$Css$fontSize(sizes.o),
										rtfeldman$elm_css$Css$displayFlex,
										rtfeldman$elm_css$Css$flexDirection(rtfeldman$elm_css$Css$column),
										rtfeldman$elm_css$Css$lineHeight(
										rtfeldman$elm_css$Css$rem(1.5)),
										rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$left),
										rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center),
										rtfeldman$elm_css$Css$marginLeft(
										rtfeldman$elm_css$Css$px(20))
									]))
							]),
						_List_fromArray(
							[
								A2(
								rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												rtfeldman$elm_css$Css$margin(
												rtfeldman$elm_css$Css$px(10)),
												rtfeldman$elm_css$Css$width(
												rtfeldman$elm_css$Css$px(420)),
												rtfeldman$elm_css$Css$maxWidth(
												rtfeldman$elm_css$Css$vw(80))
											]))
									]),
								_List_fromArray(
									[
										rtfeldman$elm_css$Html$Styled$text('With age, people get wiser and wine gets more refined.')
									])),
								A2(
								rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												rtfeldman$elm_css$Css$margin(
												rtfeldman$elm_css$Css$px(10)),
												rtfeldman$elm_css$Css$width(
												rtfeldman$elm_css$Css$px(420)),
												rtfeldman$elm_css$Css$maxWidth(
												rtfeldman$elm_css$Css$vw(80))
											]))
									]),
								_List_fromArray(
									[
										rtfeldman$elm_css$Html$Styled$text('A good story goes best with a great wine. I was always fascinated by our family’s stories and somehow I feel like they sound even more amazing with a glass of wine made from the grapes from our garden.')
									])),
								A2(
								rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												rtfeldman$elm_css$Css$margin(
												rtfeldman$elm_css$Css$px(10)),
												rtfeldman$elm_css$Css$width(
												rtfeldman$elm_css$Css$px(420)),
												rtfeldman$elm_css$Css$maxWidth(
												rtfeldman$elm_css$Css$vw(80))
											]))
									]),
								_List_fromArray(
									[
										rtfeldman$elm_css$Html$Styled$text('The phrase In ‘Vino Veritas’ is a latin phrase, meaning ‘The truth lies in Wine’.')
									])),
								A2(
								rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												rtfeldman$elm_css$Css$margin(
												rtfeldman$elm_css$Css$px(10)),
												rtfeldman$elm_css$Css$width(
												rtfeldman$elm_css$Css$px(420)),
												rtfeldman$elm_css$Css$maxWidth(
												rtfeldman$elm_css$Css$vw(80))
											]))
									]),
								_List_fromArray(
									[
										rtfeldman$elm_css$Html$Styled$text('Each bottle has its own story. The label design is dedicated to my father’s small winery and our family’s golden stories.')
									]))
							]))
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./vino/in_vino_veritas_04.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./vino/in_vino_veritas_05.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./vino/in_vino_veritas_06.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./vino/in_vino_veritas_08.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./vino/in_vino_veritas_09.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./vino/in_vino_veritas_10.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./vino/in_vino_veritas_11.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./vino/in_vino_veritas_12.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil),
				A2(
				rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('./vino/in_vino_veritas_13.jpg'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(sizes.bq),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$vw(100)),
								rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_Nil)
			]));
};
var author$project$Projects$openModal = F3(
	function (messages, id, onMobile) {
		var sizes = onMobile ? {
			o: rtfeldman$elm_css$Css$px(12),
			g: rtfeldman$elm_css$Css$vw(0),
			bg: true,
			k: rtfeldman$elm_css$Css$vw(88),
			bq: rtfeldman$elm_css$Css$vw(100)
		} : {
			o: rtfeldman$elm_css$Css$px(16),
			g: rtfeldman$elm_css$Css$vw(10),
			bg: false,
			k: rtfeldman$elm_css$Css$vw(68),
			bq: rtfeldman$elm_css$Css$vw(80)
		};
		return (id === 1) ? A3(
			author$project$Projects$modalFrame,
			onMobile,
			messages,
			author$project$Projects$heron(sizes)) : ((id === 2) ? A3(
			author$project$Projects$modalFrame,
			onMobile,
			messages,
			author$project$Projects$indagra(sizes)) : ((id === 3) ? A3(
			author$project$Projects$modalFrame,
			onMobile,
			messages,
			author$project$Projects$astroCards(sizes)) : ((id === 4) ? A3(
			author$project$Projects$modalFrame,
			onMobile,
			messages,
			author$project$Projects$bosch(sizes)) : ((id === 5) ? A3(
			author$project$Projects$modalFrame,
			onMobile,
			messages,
			author$project$Projects$plasmo(sizes)) : ((id === 6) ? A3(
			author$project$Projects$modalFrame,
			onMobile,
			messages,
			author$project$Projects$dochia(sizes)) : ((id === 7) ? A2(author$project$Projects$about, onMobile, messages) : ((id === 8) ? A3(
			author$project$Projects$modalFrame,
			onMobile,
			messages,
			author$project$Projects$crown(sizes)) : ((id === 9) ? A3(
			author$project$Projects$modalFrame,
			onMobile,
			messages,
			author$project$Projects$ec(sizes)) : ((id === 10) ? A3(
			author$project$Projects$modalFrame,
			onMobile,
			messages,
			author$project$Projects$exlibris(sizes)) : ((id === 11) ? A3(
			author$project$Projects$modalFrame,
			onMobile,
			messages,
			author$project$Projects$vino(sizes)) : ((id === 12) ? A3(
			author$project$Projects$modalFrame,
			onMobile,
			messages,
			author$project$Projects$heronLogo(sizes)) : ((id === 13) ? A3(
			author$project$Projects$modalFrame,
			onMobile,
			messages,
			author$project$Projects$kups(sizes)) : A2(rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)))))))))))));
	});
var author$project$Main$projectModal = function (model) {
	return A3(
		author$project$Projects$openModal,
		{cN: author$project$Main$CloseModal},
		model.W,
		model.aB);
};
var author$project$Main$HoverOff = {$: 5};
var author$project$Main$HoverOn = function (a) {
	return {$: 4, a: a};
};
var rtfeldman$elm_css$Css$absolute = {c8: 0, M: 'absolute'};
var rtfeldman$elm_css$Css$borderRadius = rtfeldman$elm_css$Css$prop1('border-radius');
var rtfeldman$elm_css$Css$bottom = rtfeldman$elm_css$Css$prop1('bottom');
var rtfeldman$elm_css$Css$middle = rtfeldman$elm_css$Css$prop1('middle');
var rtfeldman$elm_css$Css$relative = {c8: 0, M: 'relative'};
var rtfeldman$elm_css$Css$table = {f: 0, M: 'table'};
var rtfeldman$elm_css$Css$tableCell = {f: 0, M: 'table-cell'};
var rtfeldman$elm_css$Html$Styled$Events$onMouseOut = function (msg) {
	return A2(
		rtfeldman$elm_css$Html$Styled$Events$on,
		'mouseout',
		elm$json$Json$Decode$succeed(msg));
};
var rtfeldman$elm_css$Html$Styled$Events$onMouseOver = function (msg) {
	return A2(
		rtfeldman$elm_css$Html$Styled$Events$on,
		'mouseover',
		elm$json$Json$Decode$succeed(msg));
};
var author$project$Main$project = F5(
	function (model, picturePath, description, projectId, url) {
		return A2(
			rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$inlineBlock),
							rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$relative),
							rtfeldman$elm_css$Css$margin(
							rtfeldman$elm_css$Css$px(2)),
							rtfeldman$elm_css$Css$height(
							rtfeldman$elm_css$Css$vw(30)),
							rtfeldman$elm_css$Css$width(
							rtfeldman$elm_css$Css$vw(30))
						])),
					rtfeldman$elm_css$Html$Styled$Events$onMouseOver(
					author$project$Main$HoverOn(projectId)),
					rtfeldman$elm_css$Html$Styled$Events$onMouseOut(author$project$Main$HoverOff),
					rtfeldman$elm_css$Html$Styled$Events$onClick(
					author$project$Main$OpenModal(projectId)),
					rtfeldman$elm_css$Html$Styled$Attributes$id(
					elm$core$String$fromInt(projectId))
				]),
			_List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$href(url)
						]),
					_List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Html$Styled$img,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$src(picturePath),
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$zero),
											rtfeldman$elm_css$Css$height(
											rtfeldman$elm_css$Css$vw(30)),
											rtfeldman$elm_css$Css$width(
											rtfeldman$elm_css$Css$vw(30)),
											rtfeldman$elm_css$Css$maxWidth(
											rtfeldman$elm_css$Css$vw(100)),
											rtfeldman$elm_css$Css$borderRadius(
											rtfeldman$elm_css$Css$rem(0.2))
										]))
								]),
							_List_Nil),
							_Utils_eq(model.aU, projectId) ? A2(
							rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$absolute),
											rtfeldman$elm_css$Css$backgroundColor(author$project$Color$transparent),
											rtfeldman$elm_css$Css$width(
											rtfeldman$elm_css$Css$vw(30)),
											rtfeldman$elm_css$Css$height(
											rtfeldman$elm_css$Css$vw(4)),
											rtfeldman$elm_css$Css$bottom(
											rtfeldman$elm_css$Css$em(-1)),
											rtfeldman$elm_css$Css$borderRadius(
											rtfeldman$elm_css$Css$rem(0.2)),
											rtfeldman$elm_css$Css$color(author$project$Color$white),
											rtfeldman$elm_css$Css$fontSize(
											rtfeldman$elm_css$Css$px(16)),
											rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$table),
											rtfeldman$elm_css$Css$letterSpacing(
											rtfeldman$elm_css$Css$px(2))
										]))
								]),
							_List_fromArray(
								[
									A2(
									rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$tableCell),
													rtfeldman$elm_css$Css$verticalAlign(rtfeldman$elm_css$Css$middle)
												]))
										]),
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$text(description)
										]))
								])) : A2(rtfeldman$elm_css$Html$Styled$p, _List_Nil, _List_Nil)
						]))
				]));
	});
var author$project$Main$projectTable = function (model) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$maxWidth(
						rtfeldman$elm_css$Css$vw(100)),
						rtfeldman$elm_css$Css$fontSize(
						rtfeldman$elm_css$Css$px(0))
					])),
				rtfeldman$elm_css$Html$Styled$Attributes$id('projects')
			]),
		_List_fromArray(
			[
				A5(author$project$Main$project, model, './csb/heronlogo_main.jpg', 'HERON LOGO', 12, 'hlogo'),
				A5(author$project$Main$project, model, './heron/heron_main.jpg', 'HERON COLLECTION', 1, 'heron'),
				A5(author$project$Main$project, model, './astro/astro_main.png', 'ASTRO CARDS', 3, 'astro'),
				A5(author$project$Main$project, model, './vino/in_vino_veritas.jpg', 'IN VINO VERITAS', 11, 'vino'),
				A5(author$project$Main$project, model, './dochia/dochia_main.png', 'CASA LU\' DOCHIA', 6, 'dochia'),
				A5(author$project$Main$project, model, './indagra/indagra_landing.png', 'INDAGRA', 2, 'indagra'),
				A5(author$project$Main$project, model, './plasmo/plasmo_main.png', 'PLASMO LIFE', 5, 'plasmo'),
				A5(author$project$Main$project, model, './crown/crown_main.png', 'ICE AND WIRE CROWN', 8, 'crown'),
				A5(author$project$Main$project, model, './exlibris/exlibris_main.png', 'EX LIBRIS', 10, 'exlibris'),
				A5(author$project$Main$project, model, './bosch/bosch_main.png', 'BOSCH WALL ART', 4, 'bosch'),
				A5(author$project$Main$project, model, './ec/ec_main.png', 'EC 7', 9, '7sins'),
				A5(author$project$Main$project, model, './kups/kups5.jpg', 'Kups', 13, 'kups')
			]));
};
var author$project$Main$toTopButton = function (model) {
	return A2(
		rtfeldman$elm_css$Html$Styled$img,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$src('./buttons/up.svg'),
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$height(
						rtfeldman$elm_css$Css$vmin(3)),
						rtfeldman$elm_css$Css$width(
						rtfeldman$elm_css$Css$vmin(3)),
						rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$fixed),
						rtfeldman$elm_css$Css$bottom(
						rtfeldman$elm_css$Css$vh(31)),
						rtfeldman$elm_css$Css$right(
						rtfeldman$elm_css$Css$vw(1.5)),
						model.am ? rtfeldman$elm_css$Css$visibility(rtfeldman$elm_css$Css$visible) : rtfeldman$elm_css$Css$visibility(rtfeldman$elm_css$Css$hidden)
					])),
				rtfeldman$elm_css$Html$Styled$Events$onClick(
				author$project$Main$JumpTo('home'))
			]),
		_List_Nil);
};
var elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var elm$virtual_dom$VirtualDom$keyedNodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_keyedNodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var elm$virtual_dom$VirtualDom$nodeNS = function (tag) {
	return _VirtualDom_nodeNS(
		_VirtualDom_noScript(tag));
};
var rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles = F2(
	function (_n0, styles) {
		var newStyles = _n0.b;
		var classname = _n0.c;
		return elm$core$List$isEmpty(newStyles) ? styles : A3(elm$core$Dict$insert, classname, newStyles, styles);
	});
var rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute = function (_n0) {
	var val = _n0.a;
	return val;
};
var rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml = F2(
	function (_n6, _n7) {
		var key = _n6.a;
		var html = _n6.b;
		var pairs = _n7.a;
		var styles = _n7.b;
		switch (html.$) {
			case 4:
				var vdom = html.a;
				return _Utils_Tuple2(
					A2(
						elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n9 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n9.a;
				var finalStyles = _n9.b;
				var vdom = A3(
					elm$virtual_dom$VirtualDom$node,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n10 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n10.a;
				var finalStyles = _n10.b;
				var vdom = A4(
					elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n11 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n11.a;
				var finalStyles = _n11.b;
				var vdom = A3(
					elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n12 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n12.a;
				var finalStyles = _n12.b;
				var vdom = A4(
					elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
		}
	});
var rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml = F2(
	function (html, _n0) {
		var nodes = _n0.a;
		var styles = _n0.b;
		switch (html.$) {
			case 4:
				var vdomNode = html.a;
				return _Utils_Tuple2(
					A2(elm$core$List$cons, vdomNode, nodes),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n2 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n2.a;
				var finalStyles = _n2.b;
				var vdomNode = A3(
					elm$virtual_dom$VirtualDom$node,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n3 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n3.a;
				var finalStyles = _n3.b;
				var vdomNode = A4(
					elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n4 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n4.a;
				var finalStyles = _n4.b;
				var vdomNode = A3(
					elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n5 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n5.a;
				var finalStyles = _n5.b;
				var vdomNode = A4(
					elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(elm$core$List$cons, vdomNode, nodes),
					finalStyles);
		}
	});
var elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5(elm$core$Dict$RBNode_elm_builtin, 1, key, value, elm$core$Dict$RBEmpty_elm_builtin, elm$core$Dict$RBEmpty_elm_builtin);
	});
var rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp = F2(
	function (candidate, properties) {
		stylesFromPropertiesHelp:
		while (true) {
			if (!properties.b) {
				return candidate;
			} else {
				var _n1 = properties.a;
				var styles = _n1.b;
				var classname = _n1.c;
				var rest = properties.b;
				if (elm$core$String$isEmpty(classname)) {
					var $temp$candidate = candidate,
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				} else {
					var $temp$candidate = elm$core$Maybe$Just(
						_Utils_Tuple2(classname, styles)),
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				}
			}
		}
	});
var rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties = function (properties) {
	var _n0 = A2(rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp, elm$core$Maybe$Nothing, properties);
	if (_n0.$ === 1) {
		return elm$core$Dict$empty;
	} else {
		var _n1 = _n0.a;
		var classname = _n1.a;
		var styles = _n1.b;
		return A2(elm$core$Dict$singleton, classname, styles);
	}
};
var rtfeldman$elm_css$Css$Structure$ClassSelector = function (a) {
	return {$: 0, a: a};
};
var rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair = function (_n0) {
	var classname = _n0.a;
	var styles = _n0.b;
	return A2(
		rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
		styles,
		rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
			_List_fromArray(
				[
					rtfeldman$elm_css$Css$Structure$ClassSelector(classname)
				])));
};
var rtfeldman$elm_css$VirtualDom$Styled$toDeclaration = function (dict) {
	return rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
		elm$core$List$singleton(
			rtfeldman$elm_css$Css$Preprocess$stylesheet(
				A2(
					elm$core$List$map,
					rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair,
					elm$core$Dict$toList(dict)))));
};
var rtfeldman$elm_css$VirtualDom$Styled$toStyleNode = function (styles) {
	return A3(
		elm$virtual_dom$VirtualDom$node,
		'style',
		_List_Nil,
		elm$core$List$singleton(
			elm$virtual_dom$VirtualDom$text(
				rtfeldman$elm_css$VirtualDom$Styled$toDeclaration(styles))));
};
var rtfeldman$elm_css$VirtualDom$Styled$unstyle = F3(
	function (elemType, properties, children) {
		var unstyledProperties = A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _n0 = A3(
			elm$core$List$foldl,
			rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _n0.a;
		var styles = _n0.b;
		var styleNode = rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A3(
			elm$virtual_dom$VirtualDom$node,
			elemType,
			unstyledProperties,
			A2(
				elm$core$List$cons,
				styleNode,
				elm$core$List$reverse(childNodes)));
	});
var rtfeldman$elm_css$VirtualDom$Styled$containsKey = F2(
	function (key, pairs) {
		containsKey:
		while (true) {
			if (!pairs.b) {
				return false;
			} else {
				var _n1 = pairs.a;
				var str = _n1.a;
				var rest = pairs.b;
				if (_Utils_eq(key, str)) {
					return true;
				} else {
					var $temp$key = key,
						$temp$pairs = rest;
					key = $temp$key;
					pairs = $temp$pairs;
					continue containsKey;
				}
			}
		}
	});
var rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey = F2(
	function (_default, pairs) {
		getUnusedKey:
		while (true) {
			if (!pairs.b) {
				return _default;
			} else {
				var _n1 = pairs.a;
				var firstKey = _n1.a;
				var rest = pairs.b;
				var newKey = '_' + firstKey;
				if (A2(rtfeldman$elm_css$VirtualDom$Styled$containsKey, newKey, rest)) {
					var $temp$default = newKey,
						$temp$pairs = rest;
					_default = $temp$default;
					pairs = $temp$pairs;
					continue getUnusedKey;
				} else {
					return newKey;
				}
			}
		}
	});
var rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode = F2(
	function (allStyles, keyedChildNodes) {
		var styleNodeKey = A2(rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey, '_', keyedChildNodes);
		var finalNode = rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(allStyles);
		return _Utils_Tuple2(styleNodeKey, finalNode);
	});
var rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed = F3(
	function (elemType, properties, keyedChildren) {
		var unstyledProperties = A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _n0 = A3(
			elm$core$List$foldl,
			rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _n0.a;
		var styles = _n0.b;
		var keyedStyleNode = A2(rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A3(
			elm$virtual_dom$VirtualDom$keyedNode,
			elemType,
			unstyledProperties,
			A2(
				elm$core$List$cons,
				keyedStyleNode,
				elm$core$List$reverse(keyedChildNodes)));
	});
var rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS = F4(
	function (ns, elemType, properties, keyedChildren) {
		var unstyledProperties = A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _n0 = A3(
			elm$core$List$foldl,
			rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _n0.a;
		var styles = _n0.b;
		var keyedStyleNode = A2(rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A4(
			elm$virtual_dom$VirtualDom$keyedNodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				elm$core$List$cons,
				keyedStyleNode,
				elm$core$List$reverse(keyedChildNodes)));
	});
var rtfeldman$elm_css$VirtualDom$Styled$unstyleNS = F4(
	function (ns, elemType, properties, children) {
		var unstyledProperties = A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _n0 = A3(
			elm$core$List$foldl,
			rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _n0.a;
		var styles = _n0.b;
		var styleNode = rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A4(
			elm$virtual_dom$VirtualDom$nodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				elm$core$List$cons,
				styleNode,
				elm$core$List$reverse(childNodes)));
	});
var rtfeldman$elm_css$VirtualDom$Styled$toUnstyled = function (vdom) {
	switch (vdom.$) {
		case 4:
			var plainNode = vdom.a;
			return plainNode;
		case 0:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3(rtfeldman$elm_css$VirtualDom$Styled$unstyle, elemType, properties, children);
		case 1:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4(rtfeldman$elm_css$VirtualDom$Styled$unstyleNS, ns, elemType, properties, children);
		case 2:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3(rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed, elemType, properties, children);
		default:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4(rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS, ns, elemType, properties, children);
	}
};
var rtfeldman$elm_css$Html$Styled$toUnstyled = rtfeldman$elm_css$VirtualDom$Styled$toUnstyled;
var elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var rtfeldman$elm_css$VirtualDom$Styled$style = F2(
	function (key, val) {
		return A3(
			rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2(elm$virtual_dom$VirtualDom$style, key, val),
			_List_Nil,
			'');
	});
var rtfeldman$elm_css$Html$Styled$Attributes$style = rtfeldman$elm_css$VirtualDom$Styled$style;
var author$project$Main$view = function (model) {
	var body = A2(
		rtfeldman$elm_css$Html$Styled$nav,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(model.Z),
				A2(rtfeldman$elm_css$Html$Styled$Attributes$style, '-webkit-user-select', 'none'),
				A2(rtfeldman$elm_css$Html$Styled$Attributes$style, '-moz-user-select', 'none'),
				A2(rtfeldman$elm_css$Html$Styled$Attributes$style, '-ms-user-select', 'none'),
				A2(rtfeldman$elm_css$Html$Styled$Attributes$style, 'user-select', 'none')
			]),
		_List_fromArray(
			[
				author$project$Main$home(model),
				author$project$Main$projectTable(model),
				author$project$Main$projectModal(model),
				author$project$Main$toTopButton(model),
				author$project$Main$footer(model)
			]));
	return {
		cL: _List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$toUnstyled(body)
			]),
		de: 'Beata Csaka'
	};
};
var elm$browser$Browser$application = _Browser_application;
var author$project$Main$main = elm$browser$Browser$application(
	{cZ: author$project$Main$init, c3: author$project$Main$UrlChanged, c4: author$project$Main$LinkClicked, dc: author$project$Main$subscriptions, dg: author$project$Main$update, dj: author$project$Main$view});
_Platform_export({'Main':{'init':author$project$Main$main(
	elm$json$Json$Decode$succeed(0))(0)}});}(this));